import {
  AfterContentInit,
  Component,
  computed,
  effect,
  input,
  signal,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { AsyncJobModule } from '@ay-gosu/ui/common/async-job';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { MatTooltipModule } from '@ay-gosu/ui/material/tooltip';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { filterList } from '../../../../../../util/filter-list';
import { ParamConfig } from '../../../../../components/params-form/params-form.component';
import { PropertyConfigService } from '../../../../../service/property-config.service';
import { FlowService } from '../../../flow.service';
import { WebhookEventNode } from '../class';
import { ConfigFormComponent } from '../config-form/config-form.component';
import { FieldTypePipe } from '../field-type.pipe';
import { BotUrlComponent } from './bot-url/bot-url.component';
import { UtmTrackingFormComponent } from './utm-tracking-form/utm-tracking-form.component';

@Component({
  selector: 'flow-webhook-event-node',
  templateUrl: './webhook-event-node.component.html',
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatIconButton,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatButton,
    FieldTypePipe,
    MatFormField,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatIcon,
    MatCheckbox,
    MatIconButton,
    AsyncJobModule,
    MatTooltipModule,
    ReactiveFormsModule,
    BotUrlComponent,
    UtmTrackingFormComponent,
  ],

  host: {
    class: 'flex flex-col gap-4 w-full',
  },
})
export class WebhookEventNodeComponent implements AfterContentInit {
  public node = input.required<WebhookEventNode>();

  public bots = this.flowService.selectedBots;

  public utmTrackingForms = new FormArray<FormGroup>([]);

  public trackingProperties = signal<{ name: string; key: string }[]>(null);

  public utmTrackingProperties = computed(() =>
    this.trackingProperties().filter((config) => config.key.startsWith('utm_')),
  );

  public customerTrackingProperties = computed(() =>
    this.trackingProperties().filter(
      (config) => !config.key.startsWith('utm_'),
    ),
  );

  public path = signal('');

  public urlPath = computed(() => {
    const path = this.path();
    if (!path) return '';
    return environment.customizeUrl.replace('[event]', path);
  });

  public constructor(
    public readonly flowService: FlowService,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _propertyConfig: PropertyConfigService,
  ) {
    effect(() => {
      this.node().path = this.path();
    });
  }

  public ngAfterContentInit() {
    this.path.set(this.node().path);
    this._propertyConfig.profile$
      .pipe(filterList((config) => !!config.trackingCodeKeepDays))
      .subscribe((configs) => {
        this.trackingProperties.set(
          configs.map(({ name, key }) => ({
            name,
            key,
          })),
        );
      });
  }

  public removeFormConfig(index: number) {
    const node = this.node();
    node.formConfig.splice(index, 1);
    node.formConfig = node.formConfig.slice();
  }

  public moveFormConfig(index: number, offset: number) {
    const node = this.node();
    const configs = node.formConfig;
    const tmp = configs[index];
    configs[index] = configs[index + offset];
    configs[index + offset] = tmp;
    node.formConfig = node.formConfig.slice();
  }

  public addFormConfig() {
    const node = this.node();
    if (!(node.formConfig instanceof Array)) {
      node.formConfig = [];
    }
    node.formConfig.push({
      name: $localize`參數${node.formConfig.length + 1}`,
      key: `key${node.formConfig.length + 1}`,
      type: 'string',
      default: '',
    });
    node.formConfig = node.formConfig.slice();
  }

  public async editFormConfig(item: ParamConfig) {
    const dialogRef = this._matConnectedDialog.open(ConfigFormComponent);
    const instance = dialogRef.componentInstance;
    for (let key in item) instance[key].setValue(item[key]);
    const config = await firstValueFrom(dialogRef.afterClosed());
    if (config === undefined) return;
    for (let key in config) item[key] = config[key];
  }

  public async addUtmTrackingCodeForm() {
    const node = this.node();
    node.trackingUrlParams.push({});
  }

  public removeUtmTrackingCodeForm(index: number) {
    const node = this.node();
    node.trackingUrlParams.splice(index, 1);
  }
}
