<div fxLayout="row">
  <mat-form-field class="type" fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>事件類型</mat-label>
    <mat-select
      [(ngModel)]="node.type"
      (ngModelChange)="afterTypeChanged($event)"
      #selectType
      name="item"
    >
      <mat-select-trigger>
        <ng-container
          *ngIf="eventMap[node.type]; let selected; else: unselected"
        >
          {{ selected.label }}
        </ng-container>
        <ng-template #unselected i18n> 請選擇 </ng-template>
      </mat-select-trigger>

      <ng-container *ngFor="let event of events">
        <mat-option
          [value]="event.type"
          *ngIf="
            event.type === node.type ||
            event.enable$ === undefined ||
            (event.enable$ | async) === true
          "
        >
          <icon *ngIf="event.icon" [icon]="event.icon"></icon>
          {{ event.label }}
          <div
            class="platform"
            *ngFor="let platform of event.platforms"
            [class.line]="platform === 'LINE'"
            [class.facebook]="platform === 'FB'"
            [class.instagram]="platform === 'IG'"
            [class.survey-cake]="platform === 'SurveyCake'"
          >
            <ng-container [ngSwitch]="platform">
              <ng-template ngSwitchCase="LINE">
                <icon icon="fa-line"></icon>
              </ng-template>
              <ng-template ngSwitchCase="FB">
                <icon icon="fa-facebook-f"></icon>
              </ng-template>
              <ng-template ngSwitchCase="IG">
                <icon icon="fa-instagram"></icon>
                <img
                  src="./assets/beta.png"
                  class="align-middle w-[40px] h-[18px]"
                  alt=""
                />
              </ng-template>
              <ng-template ngSwitchCase="SurveyCake"> SurveyCake </ng-template>
            </ng-container>
          </div>
        </mat-option>
      </ng-container>
    </mat-select>
    <gosu-validator
      [(ngModel)]="node.type"
      [rules]="{ bannedOptions: true }"
      [offsetY]="20"
      [payload]="{ bannedOptions: bannedEvents }"
      [message]="'此事件已不再支援'"
    ></gosu-validator>
  </mat-form-field>
</div>

<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="24px"
  class="expert"
>
  <mat-checkbox
    [(ngModel)]="node.isSpecial"
    (ngModelChange)="node.specialCount = node.specialCount || 1"
    matTooltip="當使用者執行至此節點，才會開始偵測事件"
    i18n-matTooltip="當使用者執行至此節點，才會開始偵測事件"
    color="primary"
    i18n
  >
    額外事件
  </mat-checkbox>

  <ng-container *ngIf="node.isSpecial">
    <mat-form-field
      fxFlex="1 0 0"
      class="special-count"
      inputWithRemind
      legacyAppearance
    >
      <mat-label i18n>可執行次數</mat-label>
      <input
        matInput
        type="number"
        [ngModel]="node.specialCount"
        (ngModelChange)="node.specialCount = $event"
        matTooltip="這個額外事件被喚醒後可以執行幾次"
        i18n-matTooltip="這個額外事件被喚醒後可以執行幾次"
      />
    </mat-form-field>

    <mat-form-field
      fxFlex="1 0 0"
      class="special-target"
      inputWithRemind
      legacyAppearance
    >
      <mat-label i18n>存活對象</mat-label>
      <mat-select
        [(ngModel)]="node.specialTarget"
        matTooltip="一般來說會設定為使用者，表示接下來的事件只有觸發這個事件的使用者可以執行"
        i18n-matTooltip="
          一般來說會設定為使用者，表示接下來的事件只有觸發這個事件的使用者可以執行
        "
        i18n
      >
        <mat-option value="profile">使用者</mat-option>
        <mat-option value="bot">機器人</mat-option>
        <mat-option value="company">組織</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      fxFlex="2 0 0"
      class="special-mode"
      legacyAppearance
      inputWithRemind
    >
      <mat-label i18n>執行模式</mat-label>
      <mat-select [(ngModel)]="node.specialMode">
        <mat-option value="concurrent" i18n>併發</mat-option>
        <mat-option value="monopolize" i18n>獨佔</mat-option>
      </mat-select>
      <mat-hint *ngIf="node.specialMode === 'monopolize'" i18n>
        開始接收額外事件後，其他起始點將不會運作
      </mat-hint>
    </mat-form-field>
  </ng-container>

  <mat-icon
    *ngIf="node.isSpecial"
    matTooltip="在等待使用者回應的過程中，tmp 中的變數資料將會重置
如有需要保留的資料，可以透過設定屬性結點將資料儲存"
    i18n-matTooltip="
      在等待使用者回應的過程中，tmp 中的變數資料將會重置
      如有需要保留的資料，可以透過設定屬性結點將資料儲存
    "
    matTooltipClass="break-tooltip"
    >info</mat-icon
  >
</div>

<div class="switch" [ngSwitch]="node.type">
  <flow-webhook-event-node
    *ngSwitchCase="'WebhookEventNode'"
    [node]="$any(node)"
  >
  </flow-webhook-event-node>

  <div class="m-me-event-node" *ngSwitchCase="'MMEEventNode'">
    <mat-form-field legacyAppearance class="pl-3 pr-3">
      <mat-label i18n>網址參數</mat-label>
      <input matInput [(ngModel)]="node['ref']" />
    </mat-form-field>

    <ng-container *ngFor="let bot of flowService.selectedBots()">
      <div
        class="path"
        *ngIf="bot.type === 'Facebook'"
        (click)="copyMMEUrl(bot)"
      >
        <span class="label">{{ bot | bot }}</span>
        <span class="url">
          https://m.me/{{ bot.platformId }}?ref={{ node['ref'] }}
        </span>
      </div>
    </ng-container>
  </div>

  <div class="ig-me-event-node" *ngSwitchCase="'IGMEEventNode'">
    <mat-form-field>
      <mat-label i18n>網址參數</mat-label>
      <input matInput [(ngModel)]="node['ref']" />
    </mat-form-field>

    <ng-container *ngFor="let bot of flowService.selectedBots()">
      <div
        class="path"
        *ngIf="bot.type === 'Instagram'"
        (click)="copyIGMEUrl(bot)"
      >
        <span class="label">{{ bot | bot }}</span>
        <span class="url">
          https://ig.me/m/{{ bot.instagramAccountName }}?ref={{ node['ref'] }}
        </span>
      </div>
    </ng-container>
  </div>

  <div class="exchange-event-node" *ngSwitchCase="'ExchangeEventNode'">
    <mat-form-field legacyAppearance class="pl-3 pr-3">
      <mat-label i18n>兌換活動</mat-label>
      <mat-select
        placeholder="所有"
        i18n-placeholder="所有"
        [(ngModel)]="node['exchangeId']"
        name="exchange"
        multiple
      >
        <mat-option
          *ngFor="let exchange of exchangeService.list$ | async"
          [value]="exchange.id"
        >
          {{ exchange.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field legacyAppearance>
      <mat-label i18n>獎品</mat-label>
      <mat-select
        placeholder="所有"
        i18n-placeholder="所有"
        [(ngModel)]="node['ticketId']"
        name="ticket"
        multiple
      >
        <mat-option
          *ngFor="let ticket of ticketService.list$ | async"
          [value]="ticket.id"
        >
          {{ ticket.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="survey-cake-event-node" *ngSwitchCase="'SurveyCakeEventNode'">
    <div>
      <mat-checkbox
        [(ngModel)]="node['integrateDirectus']"
        color="primary"
        i18n
      >
        整合 Directus
      </mat-checkbox>
      <br />
      <br />
    </div>

    <ng-container *ngIf="node['integrateDirectus']; else withoutDirectus">
      <div>
        <mat-form-field legacyAppearance>
          <mat-label i18n>SVID 欄位</mat-label>
          <input matInput [(ngModel)]="node['surveyCakeUrl']" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field legacyAppearance>
          <mat-label i18n>Hash Key 欄位</mat-label>
          <input matInput [(ngModel)]="node['hashKey']" />
        </mat-form-field>

        <mat-form-field legacyAppearance>
          <mat-label i18n>IV Key 欄位</mat-label>
          <input matInput [(ngModel)]="node['ivKey']" />
        </mat-form-field>
      </div>

      <mat-divider> </mat-divider>

      <div class="url-container">
        <label i18n>感謝頁網址</label>
        <table *ngIf="flowService.selectedBots.length; else emptyBot">
          <tbody>
            <tr *ngFor="let bot of flowService.selectedBots()">
              <td>{{ bot | bot }}</td>
              <td>{{ fetchSurveyCakeDirectusThinkPageUrl(bot) }}</td>
            </tr>
          </tbody>
          <tbody>
            <tr></tr>
          </tbody>
        </table>
      </div>

      <mat-divider> </mat-divider>

      <div class="url-container">
        <label i18n>給使用者的網址</label>
        <table *ngIf="flowService.selectedBots.length; else emptyBot">
          <tbody>
            <tr *ngFor="let bot of flowService.selectedBots()">
              <td>{{ bot | bot }}</td>
              <td>{{ fetchSurveyCakeDirectusFillUrl(bot) }}</td>
            </tr>
          </tbody>
          <tbody>
            <tr></tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-template #withoutDirectus>
      <div class="pr-3 pl-3">
        <mat-form-field legacyAppearance>
          <mat-label i18n>問券連結</mat-label>
          <input
            matInput
            placeholder="https://www.surveycake.com/s/XXXXX"
            [(ngModel)]="node['surveyCakeUrl']"
          />
        </mat-form-field>
        <br />
        <img src="/assets/survey-cake-url.png" alt="" />
      </div>

      <mat-divider> </mat-divider>

      <div class="pr-3 pl-3">
        <div class="url-container">
          <label i18n>感謝頁網址</label>
          <div *ngIf="svid; else invalidSVID">
            <table *ngIf="flowService.selectedBots.length; else emptyBot">
              <tbody>
                <tr
                  *ngFor="let bot of flowService.selectedBots()"
                  (click)="copy(fetchSurveyCakeThinkPageUrl(bot))"
                  [matTooltip]="'複製 ' + fetchSurveyCakeThinkPageUrl(bot)"
                >
                  <td>{{ bot | bot }}</td>
                  <td>{{ fetchSurveyCakeThinkPageUrl(bot) }}</td>
                </tr>
              </tbody>
              <tbody>
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
        <img src="/assets/survey-cake-thank-page.png" alt="" />
      </div>

      <mat-divider> </mat-divider>

      <div class="pr-3 pl-3">
        <mat-form-field legacyAppearance>
          <mat-label>Hash Key</mat-label>
          <input matInput [(ngModel)]="node['hashKey']" />
        </mat-form-field>

        <mat-form-field legacyAppearance>
          <mat-label>IV Key</mat-label>
          <input matInput [(ngModel)]="node['ivKey']" />
        </mat-form-field>

        <img src="/assets/survey-cake-key.png" alt="" />
      </div>

      <mat-divider> </mat-divider>

      <div class="url-container pr-3 pl-3">
        <label i18n>給使用者的網址</label>
        <div *ngIf="svid; else invalidSVID">
          <table *ngIf="flowService.selectedBots.length; else emptyBot">
            <tbody>
              <tr
                *ngFor="let bot of flowService.selectedBots()"
                (click)="copy(fetchSurveyCakeFillUrl(bot))"
                [matTooltip]="'複製 ' + fetchSurveyCakeFillUrl(bot)"
              >
                <td>{{ bot | bot }}</td>
                <td>{{ fetchSurveyCakeFillUrl(bot) }}</td>
              </tr>
            </tbody>
            <tbody>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </div>

  <div
    class="add-through-promotion-event-node"
    *ngSwitchCase="'AddThroughPromotionEventNode'"
    fxLayout="row"
    fxLayoutGap="12px"
  >
    <mat-form-field fxFlex="1 0 0" legacyAppearance>
      <mat-label i18n>推廣通路</mat-label>
      <mat-select
        placeholder="所有"
        i18n-placeholder="所有"
        [(ngModel)]="node['promotionChannelId']"
        (ngModelChange)="onPromotionChannelChanged()"
      >
        <mat-select-trigger fxLayout="row">
          <ng-container
            *ngIf="selectedPromotionChannel$ | async; let promotion"
          >
            <div fxFlex="1 0 0" class="name">{{ promotion.name }}</div>
            <div class="key">{{ promotion.key }}</div>
          </ng-container>
          <ng-container *ngIf="!node['promotionChannelId']" i18n>
            所有通路
          </ng-container>
        </mat-select-trigger>

        <mat-option [value]="0" i18n> 所有通路 </mat-option>

        <mat-option
          *ngFor="let item of promotionChannelList$ | async"
          [value]="item.id"
          fxLayout="row"
        >
          <div fxFlex="1 0 0" class="name">{{ item.name }}</div>
          <div class="key">{{ item.key }}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="selectedPromotionChannel$ | async; let promotion">
      <mat-form-field
        *ngIf="promotion.type === 'URL'"
        fxFlex="1 0 0"
        legacyAppearance
      >
        <mat-label i18n>子推廣通路</mat-label>
        <mat-select
          placeholder="所有"
          i18n-placeholder="所有"
          [(ngModel)]="node['subPromotionChannelId']"
          (ngModelChange)="onSubPromotionChannelChanged()"
        >
          <mat-select-trigger fxLayout="row">
            <ng-container
              *ngIf="selectedSubPromotionChannel$ | async; let subPromotion"
            >
              <div fxFlex="1 0 0" class="name">{{ subPromotion.name }}</div>
              <div class="key">{{ subPromotion.key }}</div>
            </ng-container>
            <ng-container *ngIf="!node['subPromotionChannelId']" i18n>
              所有通路
            </ng-container>
          </mat-select-trigger>

          <mat-option [value]="0" i18n> 所有通路 </mat-option>

          <mat-option
            *ngFor="let item of subPromotionChannelList$ | async"
            [value]="item.subPromotion.id"
            fxLayout="row"
          >
            <div fxFlex="1 0 0" class="name">
              {{ item.subPromotion.name }}
            </div>
            <div class="key">{{ item.subPromotion.name }}</div>
          </mat-option>
        </mat-select>
      </mat-form-field></ng-container
    >
  </div>

  <div
    class="success-invite-friend-through-promotion-event-node"
    *ngSwitchCase="'SuccessInviteFriendThroughPromotionEventNode'"
  >
    <mat-form-field fxFlex="1 0 0" legacyAppearance>
      <mat-label i18n>推廣通路</mat-label>
      <mat-select
        placeholder="所有"
        i18n-placeholder="所有"
        [(ngModel)]="node['promotionChannelId']"
        (ngModelChange)="onPromotionChannelChanged()"
      >
        <mat-select-trigger fxLayout="row">
          <ng-container
            *ngIf="selectedPromotionChannel$ | async; let promotion"
          >
            <div fxFlex="1 0 0" class="name">{{ promotion.name }}</div>
            <div class="key">{{ promotion.key }}</div>
          </ng-container>
          <ng-container *ngIf="!node['promotionChannelId']" i18n>
            所有通路
          </ng-container>
        </mat-select-trigger>

        <mat-option [value]="0" i18n> 所有通路 </mat-option>
        <mat-option
          *ngFor="let item of promotionChannelList$ | async"
          [value]="item.id"
          fxLayout="row"
        >
          <div fxFlex="1 0 0" class="name">{{ item.name }}</div>
          <div class="key">{{ item.key }}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="demo" *ngIf="demoValue">
  <h3 i18n>你將會有以下變數可以使用</h3>
  <div class="gosu-json-viewer-wrap">
    <gosu-json-viewer [json]="demoValue" [expandedDeep]="1"></gosu-json-viewer>
  </div>
</div>

<ng-template #emptyBot>
  <div class="empty-bot" i18n>
    該流程目前未對任何機器人啟用，所以沒有辦法產生網址
  </div>
</ng-template>

<ng-template #invalidSVID>
  <div class="invalid-svid" i18n>請先填寫問券網址</div>
</ng-template>
