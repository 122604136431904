<mat-card>
  <mat-card-header>
    <mat-card-title i18n>動作</mat-card-title>
  </mat-card-header>

  <mat-divider> </mat-divider>

  <mat-card-content [formGroup]="formGroup">
    <div class="label">
      <div class="title">{{ 'type' | cht }}</div>
      <programmable
        [title]="'type' | cht"
        [value]="formGroup.value['type']"
        (valueChange)="formGroup.controls['type'].setValue($event); doChange()"
      >
        <mat-form-field legacyAppearance>
          <mat-select
            formControlName="type"
            (selectionChange)="changeActionType($event.value); doChange()"
          >
            <ng-container *ngFor="let option of options.Action">
              <mat-option *ngIf="option.enable" [value]="option.value">
                {{ option.text || option.value }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </programmable>
    </div>

    <div
      class="label"
      *ngFor="let property of actionProperties[formGroup.value.type]"
    >
      <div class="title">
        {{ property.name | cht }}
      </div>

      <programmable
        *ngIf="property.options"
        [title]="property.name | cht"
        [value]="formGroup.value[property.name]"
        (valueChange)="
          formGroup.controls[property.name].setValue($event); doChange()
        "
      >
        <mat-form-field legacyAppearance>
          <mat-select
            [formControlName]="property.name"
            (selectionChange)="
              property.name === 'scheme' ? false : resetTimes();
              property.name === 'scheme'
                ? changeUrlType($event.value)
                : doChange()
            "
          >
            <ng-container *ngIf="property.options">
              <mat-option
                *ngFor="let option of property.options"
                [value]="option.value"
              >
                {{ option.text || option.value }}
              </mat-option>
            </ng-container>

            <ng-container *ngIf="property.asyncOptions">
              <mat-option
                *ngFor="let option of property.asyncOptions | async"
                [value]="option.value"
              >
                {{ option.text || option.value }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </programmable>

      <programmable
        *ngIf="property.hint"
        [title]="property.name | cht"
        [value]="formGroup.value[property.name]"
        (valueChange)="
          formGroup.controls[property.name].setValue($event); doChange()
        "
      >
        <gosu-datetime-picker
          [value]="formGroup.value[property.name]"
          [inputFormat]="hint"
          [hint]="property.error || hint"
          [type]="formGroup.value.mode"
          [error]="!!property.error"
          (valueChange)="
            formGroup.controls[property.name].setValue($event); doChange()
          "
        >
        </gosu-datetime-picker>
      </programmable>

      <programmable
        *ngIf="
          !property.hint && !property.options && property.name !== 'altUri'
        "
        [title]="property.name | cht"
        [value]="formGroup.value[property.name]"
        (valueChange)="
          formGroup.controls[property.name].setValue($event); doChange()
        "
      >
        <mat-form-field
          [class.mat-form-field-invalid]="property.error"
          legacyAppearance
        >
          <input
            matInput
            color="primary"
            [formControlName]="property.name"
            (change)="doChange()"
            (focus)="flexEditorService.isInputFocus$.next(true)"
            (blur)="flexEditorService.isInputFocus$.next(false)"
          />

          @if (property.name === 'uri') {
            <button
              mat-icon-button
              matSuffix
              (click)="openUrlBuilderDialog(property.name)"
              matTooltip="編輯 UTM 參數"
              i18n-matTooltip="編輯 UTM 參數"
            >
              <mat-icon> edit </mat-icon>
            </button>
          }

          <mat-hint>
            <span [class.mat-error]="property.error">
              {{ property.error || (property.hint === 'format' ? hint : '') }}
            </span>
          </mat-hint>
        </mat-form-field>
      </programmable>

      <programmable
        *ngIf="
          !property.hint && !property.options && property.name === 'altUri'
        "
        [title]="property.name | cht"
        [formGroup]="$any(formGroup.controls.altUri)"
        [value]="formGroup.value.altUri.desktop"
        (valueChange)="
          $any(formGroup.controls.altUri).controls.desktop.setValue($event);
          doChange()
        "
      >
        <mat-form-field
          [class.mat-form-field-invalid]="property.error"
          legacyAppearance
        >
          <input
            matInput
            color="primary"
            formControlName="desktop"
            (change)="doChange()"
            (focus)="flexEditorService.isInputFocus$.next(true)"
            (blur)="flexEditorService.isInputFocus$.next(false)"
          />

          <button
            mat-icon-button
            matSuffix
            (click)="openUrlBuilderDialog(property.name)"
            matTooltip="編輯 UTM 參數"
            i18n-matTooltip="編輯 UTM 參數"
          >
            <mat-icon> edit </mat-icon>
          </button>

          <mat-hint>
            <span [class.mat-error]="property.error">
              {{ property.error || (property.hint === 'format' ? hint : '') }}
            </span>
          </mat-hint>
        </mat-form-field>
      </programmable>
    </div>
  </mat-card-content>
</mat-card>
