import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Inject,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelActionRow,
  MatExpansionPanelHeader,
} from '@angular/material/expansion';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromotionChannelModel, StaticModel } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { MatTooltipModule } from '@ay-gosu/ui/material/tooltip';
import { Button, Card } from '@ay/bot';
import { firstValueFrom, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { FileDirective } from '../../../components/file.directive';
import { GosuValidatorComponent } from '../../../components/gosu-validator/gosu-validator.component';
import { ImagePickerComponent } from '../../../components/image-picker/image-picker.component';
import { UrlBuilderDialog } from '../../../components/url-builder/url-builder.component';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../material/tooltip/tooltip';
import { PackageFactoryService } from '../../package-factory.service';
import { Package } from '../../package.class';
import { CardComponent } from '../card/card.component';
import { CardsMessage } from '../cards.message';

@Component({
  selector: 'ms-card-creator-dialog',
  templateUrl: './general-editor.dialog.html',
  styleUrls: ['./general-editor.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    CardComponent,
    ImagePickerComponent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    GosuValidatorComponent,
    MatAccordion,
    NgFor,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelActionRow,
    MatButton,
    MatSelect,
    NgIf,
    MatOption,
    FileDirective,
    MatIcon,
    MatTooltip,
    MatProgressBar,
    AsyncPipe,
    LegacyAppearanceDirective,
    MatSuffix,
    MatIconButton,
    MatTooltipModule,
  ],
})
export class CardCreatorDialog {
  public card: Card;
  public message: CardsMessage;
  public package: Package;

  @ViewChildren('buttonPanel')
  protected buttonPanels: QueryList<MatExpansionPanel>;

  @ViewChild('uploadProgressDialog')
  protected uploadProgressDialog: TemplateRef<any>;

  public uploadProgress: number;

  public promotions$ = of([]).pipe(
    mergeMap((_) =>
      PromotionChannelModel.list(1, 100).then((res) =>
        res.data.filter((row) => row.type === 'SHARE_MESSAGE'),
      ),
    ),
  );

  public constructor(
    private readonly _matDialogRef: MatDialogRef<CardCreatorDialog, Card>,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _applicationRef: ApplicationRef,
    private readonly _matSnackbar: MatSnackBar,
    private readonly _matDialog: MatConnectedDialog,
    public readonly packageFactoryService: PackageFactoryService,
    @Inject(MAT_DIALOG_DATA)
    data: { card: Card; message: CardsMessage; package: Package },
  ) {
    this.card = data.card;
    this.message = data.message;
    this.package = data.package;
  }

  public error = null;

  public confirm() {
    this._matDialogRef.close(this.card);
  }

  public onChanged() {
    this._changeDetectorRef.markForCheck();
    this.message.changed();
  }

  public remove(index: number) {
    this.card.buttons.splice(index, 1);
  }

  public add() {
    this.card.buttons.push(new Button.Message($localize`未命名按鈕`, ''));
    this.onChanged();
    this._applicationRef.tick();
    if (this.buttonPanels.last) {
      this.buttonPanels.last.open();
    }
  }

  public uploadFile(button: Button.Uri, files: FileList) {
    const file = files.item(0);
    const dialogRef = this._matDialog.open(this.uploadProgressDialog, {
      disableClose: true,
    });

    StaticModel.upload(file.name, file).subscribe({
      next: (response) => {
        this.uploadProgress = response.percentage * 100;
        if (response.status === 'all-done') {
          button.uri = response.result;
          dialogRef.close();
          this._matSnackbar.open($localize`檔案上傳成功`);
          this.onChanged();
        }
      },
      error: (error) => {
        console.error(error);
        dialogRef.close();
        this._matSnackbar.open($localize`檔案上傳發生錯誤`);
      },
    });
  }

  public async openUrlBuilderDialog(button: Button.Uri) {
    const dialogRef = this._matDialog.open(UrlBuilderDialog, {
      data: { url: button.uri, from: 'general-card' },
    });

    const response = await firstValueFrom(dialogRef.afterClosed());
    if (!response) return;
    button.uri = response;
  }
}
