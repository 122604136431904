<div class="header" i18n>編輯海報</div>

<div class="container">
  <gosu-image-picker
    [(value)]="poster.content"
    [useCode]="true"
    [autoSize]="true"
    [width]="1040"
  >
  </gosu-image-picker>

  <div class="area" *ngFor="let area of poster.areas">
    <mat-form-field class="select" legacyAppearance>
      <mat-label i18n>類型</mat-label>
      <mat-select
        [(ngModel)]="area.type"
        (ngModelChange)="$any(area).content = ''"
        i18n
      >
        <mat-option *ngIf="package.enabledActions.uri" value="uri">
          連結
        </mat-option>

        <mat-option *ngIf="package.enabledActions.message" value="message">
          說話
        </mat-option>

        <ng-container
          *ngIf="packageFactoryService.enablePromotionChannel$ | async"
        >
          <mat-option
            *ngIf="package.enabledActions.shareMessageThroughPromotion"
            value="share-message-through-promotion"
          >
            分享機器人 (推廣通路)
          </mat-option>

          <mat-option
            *ngIf="package.enabledActions.addThroughPromotion"
            value="add-through-promotion"
          >
            加入機器人 (推廣通路)
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      floatLabel="always"
      *ngIf="area.type === 'message'"
      legacyAppearance
    >
      <mat-label i18n>訊息內容</mat-label>
      <input matInput type="text" [(ngModel)]="area['content']" />
    </mat-form-field>

    <mat-form-field
      floatLabel="always"
      *ngIf="area.type === 'uri'"
      legacyAppearance
    >
      <input
        matInput
        type="text"
        placeholder="網址"
        i18n-placeholder="網址"
        [(ngModel)]="area['content']"
      />

      <button
        mat-icon-button
        matSuffix
        (click)="openUrlBuilderDialog(area)"
        matTooltip="編輯 UTM 參數"
        i18n-matTooltip="編輯 UTM 參數"
      >
        <mat-icon> edit </mat-icon>
      </button>

      <gosu-validator
        [(ngModel)]="area.content"
        [rules]="{ required: true, uri: true }"
        [offsetY]="20"
      ></gosu-validator>
    </mat-form-field>

    <mat-form-field
      legacyAppearance
      floatLabel="always"
      *ngIf="area.type === 'share-message-through-promotion'"
    >
      <mat-label i18n>推廣通路</mat-label>
      <mat-select [(ngModel)]="area['promotionId']">
        <mat-option
          *ngFor="let promotion of promotions$ | async"
          [value]="promotion.id"
        >
          {{ promotion.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="footer tar">
  <button mat-raised-button (click)="submit()" color="primary" i18n>
    製作完成
  </button>
</div>
