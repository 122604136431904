import { AsyncPipe } from '@angular/common';
import { Component, Inject, signal } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { firstValueFrom } from 'rxjs';
import { PropertyConfigDto } from '../../../../../../../libs/@ay-gosu/server-shared/src';
import { KonamiCode } from '../../service/konami-code.service';
import { PropertyConfigService } from '../../service/property-config.service';

@Component({
  selector: 'url-builder',
  templateUrl: './url-builder.component.html',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  standalone: true,
  host: { class: 'p-4' },
})
export class UrlBuilderDialog {
  public readonly url = new FormControl('');

  public readonly params = new FormArray<
    FormGroup<{ key: FormControl<string>; value: FormControl<string> }>
  >([]);

  public readonly utmParams = new FormGroup<{
    [key: string]: FormControl<string>;
  }>({});

  public readonly customizeParams = new FormGroup<{
    [key: string]: FormControl<string>;
  }>({});

  public utmPropertyConfigs: PropertyConfigDto[] = [];

  public customizePropertyConfigs: PropertyConfigDto[] = [];

  public from = signal('');

  public form = new FormGroup({
    url: this.url,
    utmParams: this.utmParams,
    customizeParams: this.customizeParams,
    params: this.params,
  });

  public constructor(
    private readonly _matDialogRef: MatDialogRef<UrlBuilderDialog>,
    public readonly konamiCode: KonamiCode,
    private readonly _propertyConfigService: PropertyConfigService,
    @Inject(MAT_DIALOG_DATA)
    private readonly _matDialogData: { url: string; from: string },
  ) {
    this.load();
    this.from.set(this._matDialogData.from);
  }

  private async load() {
    await this._loadTrackablePropertyConfigs();

    this._createFormGroup();

    this._patchValue();
  }

  private _createFormGroup() {
    for (const config of this.utmPropertyConfigs) {
      if (this.utmParams.get(config.key)) continue;
      this.utmParams.addControl(config.key, new FormControl(''));
    }

    for (const config of this.customizePropertyConfigs) {
      if (this.customizeParams.get(config.key)) continue;
      this.customizeParams.addControl(config.key, new FormControl(''));
    }
  }

  private async _loadTrackablePropertyConfigs() {
    const configs = await firstValueFrom(this._propertyConfigService.profile$);

    for (const config of configs) {
      if (!config.trackingCodeKeepDays) continue;
      if (config.key.startsWith('utm_')) {
        this.utmPropertyConfigs.push(config);
      } else {
        this.customizePropertyConfigs.push(config);
      }
    }
  }

  private _patchValue() {
    try {
      const url = new URL(this._matDialogData.url);

      this.url.setValue(url.href.replace(url.search, ''));

      for (const [key, value] of url.searchParams.entries()) {
        const isUtm = this.utmPropertyConfigs.some(
          (param) => param.key === key,
        );
        if (isUtm) {
          this.utmParams.get(key)?.setValue(value);
          continue;
        }

        const isCustomize = this.customizePropertyConfigs.some(
          (param) => param.key === key,
        );
        if (isCustomize) {
          this.customizeParams.get(key)?.setValue(value);
          continue;
        }

        this.params.push(
          new FormGroup({
            key: new FormControl(key),
            value: new FormControl(value),
          }),
        );
      }
    } catch (error) {}
  }

  public addParam() {
    const param = new FormGroup({
      key: new FormControl(''),
      value: new FormControl(''),
    });
    this.params.push(param);
  }

  public removeParam(index: number) {
    this.params.removeAt(index);
  }

  public close() {
    this._matDialogRef.close();
  }

  public confirm() {
    const args = { url: this.url.value, isFirst: true };
    for (const param of this.params.controls) {
      const key = param.get('key')?.value;
      const value = param.get('value')?.value;
      this.appendParams(args, key, value);
    }

    for (const [key, value] of Object.entries(this.utmParams.value)) {
      this.appendParams(args, key, value);
    }

    for (const [key, value] of Object.entries(this.customizeParams.value)) {
      this.appendParams(args, key, value);
    }

    this._matDialogRef.close(args.url);
  }

  private appendParams(
    args: { isFirst: boolean; url: string },
    key: string,
    value: string,
  ) {
    if (!value) return;
    if (!key) return;

    if (args.isFirst) {
      args.url += '?';
      args.isFirst = false;
    } else {
      args.url += '&';
    }

    key = encodeURIComponent(key);
    if (!(value?.startsWith('${') && value?.endsWith('}'))) {
      value = encodeURIComponent(value);
    }

    args.url += `${key}=${value}`;
  }
}
