<mat-form-field class="col-span-1" legacyAppearance>
  <mat-label i18n>啟用機器人</mat-label>
  <input matInput [value]="name()" disabled />
  @if (name() == '') {
    <mat-hint>
      <span i18n class="!text-red-500"> 請先選擇啟用此流程的機器人 </span>
    </mat-hint>
  }
</mat-form-field>

<div class="col-span-2 flex flex-row gap-1">
  <mat-form-field class="flex-1" legacyAppearance [matTooltip]="normalUrl()">
    <mat-label i18n>生成網址</mat-label>
    <input matInput [value]="normalUrl()" disabled />
    @if (name() == '') {
      <mat-hint>
        <span i18n class="!text-red-500">
          沒有啟用此流程的機器人，故無法生成網址
        </span>
      </mat-hint>
    }
  </mat-form-field>

  <ng-container *asyncJob="urls$; let urls; loading: empty">
    @if (urls.length === 1) {
      <button
        mat-icon-button
        (click)="copy(urls[0].url)"
        matTooltip="複製網址"
        class="mt-4"
      >
        <mat-icon>content_copy</mat-icon>
      </button>

      <button
        mat-icon-button
        (click)="downloadQrCode(urls[0].url)"
        matTooltip="下載 QRCode"
        class="mt-4"
      >
        <mat-icon>download</mat-icon>
      </button>
    } @else {
      <button
        mat-icon-button
        matTooltip="複製網址"
        class="mt-4"
        [matMenuTriggerFor]="copyMenu"
      >
        <mat-icon>content_copy</mat-icon>
      </button>

      <button
        mat-icon-button
        matTooltip="下載 QRCode"
        class="mt-4"
        [matMenuTriggerFor]="downloadQrCodeMenu"
      >
        <mat-icon>download</mat-icon>
      </button>
    }
  </ng-container>
</div>

<ng-template #empty> </ng-template>

<mat-menu #copyMenu="matMenu">
  <ng-container *asyncJob="urls$; let urls; loading: empty">
    @for (url of urls; track $index) {
      <button mat-menu-item (click)="copy(url.url)">{{ url.label }}</button>
    }
  </ng-container>
</mat-menu>

<mat-menu #downloadQrCodeMenu="matMenu">
  <ng-container *asyncJob="urls$; let urls; loading: empty">
    @for (url of urls; track $index) {
      <button mat-menu-item (click)="downloadQrCode(url.url)">
        {{ url.label }}
      </button>
    }
  </ng-container>
</mat-menu>
