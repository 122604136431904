import {
  AsyncPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import {
  AfterContentInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import {
  BotDto,
  PromotionChannelFetchRankResponse,
  PromotionChannelListResponseRow,
  PromotionChannelModel,
} from '@ay-gosu/server-shared';
import { Bot } from '@ay/bot';
import { createRandomString } from '@ay/util';
import moment from 'moment';
import { ReplaySubject, firstValueFrom, of } from 'rxjs';
import { map, mergeMap, shareReplay, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { GosuValidatorComponent } from '../../../../components/gosu-validator/gosu-validator.component';
import { IconComponent } from '../../../../components/icon/icon.component';
import { JsonViewerComponent } from '../../../../components/json-viewer/json-viewer.component';
import { InputWithRemindDirective } from '../../../../material/legacy/mat-form-field/input-with-remind.directive';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { BotPipe } from '../../../../pipe/bot.pipe';
import { BotService } from '../../../../service/bot.service';
import { ClipboardService } from '../../../../service/clipboard.service';
import { ExchangeService } from '../../../../service/exchange.service';
import { PropertyConfigService } from '../../../../service/property-config.service';
import { TagService } from '../../../../service/tag.service';
import { TicketService } from '../../../../service/ticket.service';
import { TokenService } from '../../../../service/token.service';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { EventNode } from './class';
import { demo } from './demo';
import { EventNodeService } from './event';
import { WebhookEventNodeComponent } from './webhook-event-node/webhook-event-node.component';

@Component({
  selector: 'flow-event-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatSelectTrigger,
    NgIf,
    NgFor,
    MatOption,
    IconComponent,
    NgSwitch,
    NgSwitchCase,
    GosuValidatorComponent,
    MatCheckbox,
    MatTooltip,
    MatInput,
    MatHint,
    MatIcon,
    MatDivider,
    JsonViewerComponent,
    AsyncPipe,
    BotPipe,
    LegacyAppearanceDirective,
    InputWithRemindDirective,
    WebhookEventNodeComponent,
  ],
})
export class EventFormComponent
  extends FormComponent<EventNode>
  implements AfterContentInit, OnDestroy, OnInit
{
  public specialTargetMap = {
    profile: $localize`指定使用者`,
    bot: $localize`相同機器人`,
    company: $localize`所有人`,
  };

  public methods = ['get', 'post', 'put', 'delete', 'head', 'options'];

  public specialTargets = [];

  public events = this._eventNodeService.events;

  public bannedEvents = [];

  public eventMap = this._eventNodeService.events.reduce(
    (prev, event) => ({ ...prev, [event.type]: event }),
    {},
  );
  private prevType: string;

  public promotionChannelList$ = of(1).pipe(
    mergeMap((_) => PromotionChannelModel.list(1, 100)),
    map((res) => res.data),
    shareReplay(1),
  );

  public selectedPromotionChannel$ =
    new ReplaySubject<PromotionChannelListResponseRow>(1);

  public subPromotionChannelList$ = this.selectedPromotionChannel$.pipe(
    mergeMap(async (promotion) => {
      this.selectedSubPromotionChannel$.next(null);
      if (promotion && promotion.type === 'URL') {
        const res = await PromotionChannelModel.fetchRank(
          promotion.id,
          moment().format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
          1,
          100,
          {},
        );
        return res;
      } else {
        return {
          total: 0,
          data: [],
        } as PromotionChannelFetchRankResponse;
      }
    }),
    map((response) => response.data),
    shareReplay(1),
  );

  public selectedSubPromotionChannel$ = new ReplaySubject<{
    id: number;
    name: string;
    key: string;
  }>(1);

  public constructor(
    public readonly elementRef: ElementRef,
    public readonly flowService: FlowService,
    public readonly exchangeService: ExchangeService,
    public readonly ticketService: TicketService,
    private readonly _clipboardService: ClipboardService,
    private readonly _propertyConfigService: PropertyConfigService,
    private readonly _tagService: TagService,
    private readonly _botService: BotService,
    private readonly _eventNodeService: EventNodeService,
    private readonly _tokenService: TokenService,
  ) {
    super(elementRef);
    this.specialTargets = Object.keys(this.specialTargetMap);
  }

  public ngOnInit(): void {
    this.bannedEvents = this.node.bannedEvents;
  }

  public ngOnDestroy(): void {
    this.node.checkError();
  }

  public ngAfterContentInit(): void {
    setTimeout(() => {
      this.afterTypeChanged(this.node.type);
    });
  }

  public async onPromotionChannelChanged() {
    const list = await firstValueFrom(this.promotionChannelList$);
    const promotionId = this.node['promotionChannelId'];
    const promotion = list.find((item) => item.id === promotionId);
    this.selectedPromotionChannel$.next(promotion);
  }

  public async onSubPromotionChannelChanged() {
    const list = await firstValueFrom(this.subPromotionChannelList$);
    const subPromotionId = this.node['subPromotionChannelId'];
    const row = list.find((item) => item.subPromotion.id === subPromotionId);
    this.selectedSubPromotionChannel$.next(row?.subPromotion);
  }

  private updateProfileDemoProperty = this._propertyConfigService.all$
    .pipe(takeUntil(this.destroy$))
    .subscribe((list) => {
      demo.profile.property = { $expanded: false };
      demo.bot.property = { $expanded: false };
      demo.company.property = { $expanded: false };
      list.map((property) => {
        const target = this.fetchTarget(property.targetType);
        if (target === undefined) return;
        const targetProperty = target.property;
        targetProperty['$' + property.key] = property.name;
        targetProperty[property.key] = this.defaultValue(property);
      });
    });

  private updateProfileDemoTag = this._tagService.profile.all.list$
    .pipe(takeUntil(this.destroy$))
    .subscribe((list) => {
      demo.profile.tag = { $expanded: false };
      list.slice(0, 10).map((tag) => {
        demo.profile.tag['$' + tag.id] = tag.name;
        demo.profile.tag[tag.id] = true;
      });
    });

  private updateBotInfo = this._botService.all$
    .pipe(takeUntil(this.destroy$))
    .subscribe((list) => {
      if (list.length === 0) return;
      const bot = list[0];
      demo.profile.botId = bot.id;
      demo.bot.id = bot.id;
      demo.bot.name = bot.name;
    });

  private updateCompanyInfo = this._tokenService.rxCompany$
    .pipe(takeUntil(this.destroy$))
    .subscribe((company) => {
      if (!company) return;
      demo.profile.companyId = company.id;
      demo.bot.companyId = company.id;
      demo.company.id = company.id;
      demo.company.name = company.name;
    });

  private fetchTarget(type: string) {
    switch (type) {
      case 'profile':
        return demo.profile;

      case 'bot':
        return demo.bot;

      case 'company':
        return demo.company;
    }
  }

  private defaultValue(property) {
    switch (property.dataType) {
      case 'string':
        return 'TEST';

      case 'number':
        return 1;

      case 'list':
        return ['A', 'B', 'C'];
    }
  }

  public copy(url: string) {
    this._clipboardService.copy(url);
  }

  public copyMMEUrl(bot: BotDto) {
    let url = `https://m.me/${bot.platformId}?ref=${this.node['ref']}`;
    this._clipboardService.copy(url);
  }

  public copyIGMEUrl(bot: BotDto) {
    let url = `https://ig.me/m/${bot.instagramAccountName}?ref=${this.node['ref']}`;
    this._clipboardService.copy(url);
  }

  public afterTypeChanged(type: string) {
    this.ensureRef();

    const event = this.events.find((_type) => _type.type === type);

    if (this.prevType && this.prevType !== type) {
      let newNode: EventNode = new event.class();
      for (let key of newNode.extraProperties) {
        this.node[key] = newNode[key];
      }
      this.node.type = type;
      this.node.extraProperties = newNode.extraProperties;
      this.node['__proto__'] = newNode['__proto__'];
      this.node.getDisplay().then((display) => (this.node.display = display));
    }

    this.prevType = type;

    if (
      type == 'AddThroughPromotionEventNode' ||
      type == 'SuccessInviteFriendThroughPromotionEventNode'
    ) {
      this.onPromotionChannelChanged();
      this.onSubPromotionChannelChanged();
    }

    this.updateDemoValue(event);
  }

  public demoValue: any = null;

  private updateDemoValue(event: {
    type: string;
    class: any;
    label: string;
    demo?: any;
  }) {
    this.demoValue = event.demo;
    if (this.demoValue) {
      if (this.demoValue.property) {
        this.demoValue.property = demo.profile.property;
      }
      if (this.demoValue.tag) {
        this.demoValue.tag = demo.profile.tag;
      }
    }
  }

  public fetchSurveyCakeDirectusThinkPageUrl(bot: Bot) {
    return environment.surveyCakeThankPageUrl.replace(
      '[botId]',
      bot.id.toString(),
    );
  }

  public fetchSurveyCakeThinkPageUrl(bot: Bot) {
    const svid = this.svid;

    if (!svid) {
      return '';
    }

    return environment.surveyCakeThankPageUrl
      .replace('[botId]', bot.id.toString())
      .replace('[svid]', svid);
  }

  public fetchSurveyCakeDirectusFillUrl(bot: Bot) {
    return environment.surveyCakeUrl.replace('[botId]', bot.id.toString());
  }

  public fetchSurveyCakeFillUrl(bot: Bot) {
    const svid = this.svid;

    if (!svid) {
      return '';
    }

    return environment.surveyCakeUrl
      .replace('[botId]', bot.id.toString())
      .replace('[svid]', svid);
  }

  private ensureRef() {
    if (!this.node['ref']) {
      this.node['ref'] = createRandomString(
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
        6,
      );
    }
  }

  public get svid() {
    const surveyCakeUrl = this.node['surveyCakeUrl'];
    if (!surveyCakeUrl) {
      return '';
    }

    const svid = surveyCakeUrl.replace('https://www.surveycake.com/s/', '');

    if (!/^[A-Za-z0-9]+$/gi.test(svid)) {
      return '';
    }

    return svid;
  }
}
