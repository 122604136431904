import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { wsc } from '@ay-gosu/server-shared';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

type LogEvent = {
  type: string;
  target?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  public server: string;

  private matSnackBarOption = { duration: 500 };

  public status$ = new BehaviorSubject<boolean>(false);

  public log$ = new BehaviorSubject<LogEvent>({ type: $localize`就緒` });

  public version$ = this.status$.pipe(
    filter((status) => status),
    map((status) => wsc.serverVersion),
  );

  public _connectMonitor = this.status$
    .pipe(filter((status) => status))
    .subscribe((status) => {
      this.matSnackBar.open(
        $localize`成功與伺服器建立連線`,
        '',
        this.matSnackBarOption,
      );
    });

  private _logNotifyHandle = this.log$.subscribe((event) => {
    this.server = null;
    console.info(
      $localize`[連線狀態] ${event.type} ${
        event.target ? `: ${event.target}` : ''
      }`,
    );

    switch (event.type) {
      case '伺服器皆無回應':
        this.matSnackBar.open(
          $localize`伺服器皆無回應，請確認網路連線狀況`,
          '',
          this.matSnackBarOption,
        );
        break;

      case '嘗試重新連線':
        this.matSnackBar.open(
          $localize`嘗試重新連線`,
          '',
          this.matSnackBarOption,
        );
        break;

      case '連線至伺服器':
        this.matSnackBar.open(
          $localize`嘗試與伺服器連線`,
          '',
          this.matSnackBarOption,
        );
        this.server = event.target;
        break;
    }
  });

  private _catchEvent = wsc.status.subscribe(async (type) => {
    this.log$.next({ type, target: wsc.connectedServer });

    let status = type === '連線至伺服器';
    // 當網路中斷時間很短時，socket.io 可能在內部就已經完成重連過程，因此當狀態回復時會直接觸發 connect 事件
    // ，而不是先觸發 reconnecting 再 reconnect。
    // 但對伺服器來說，這樣的行為是斷線重連而不是第一次 connect，
    // 因此當狀態回復時，先恢復為 false，再恢復為 true, 才能正確 loginViaToken
    if (this.status$.value && status) {
      this.status$.next(false);
      await new Promise((resolve) => setTimeout(resolve, 0));
      if (this.status$.value === status) return;
      this.status$.next(status);
      return;
    }
    if (this.status$.value === status) return;
    this.status$.next(status);
  });

  public async awaitConnected() {
    return firstValueFrom(this.status$.pipe(first((status) => status)));
  }

  public constructor(
    public matSnackBar: MatSnackBar,
    public applicationRef: ApplicationRef,
  ) {
    wsc.connect(...environment.serverUrl);
    this.matSnackBar.open(
      $localize`嘗試與伺服器連線`,
      '',
      this.matSnackBarOption,
    );

    window['disconnect'] = () => {
      this.status$.next(false);
      applicationRef.tick();
    };

    window['connect'] = () => {
      this.status$.next(true);
      applicationRef.tick();
    };
  }
}
