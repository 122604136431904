import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AsyncPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {
  MatChipGrid,
  MatChipInput,
  MatChipRemove,
  MatChipRow,
} from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelActionRow,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { ExchangeModel, PropertyConfigDto } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { limitRange } from '@ay/util';
import { delay } from 'bluebird';
import { InstagramPostSelectorDialog } from 'projects/backstage/src/app/dialog/instagram-post-selector-dialog/instagram-post-selector.dialog';
import { RedirectPageDialog } from 'projects/backstage/src/app/dialog/redirect-page/redirect-page.dialog';
import { firstValueFrom } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DatetimePickerComponent } from '../../../../components/datetime-picker/datetime-picker.component';
import { GosuValidatorComponent } from '../../../../components/gosu-validator/gosu-validator.component';
import { EditorComponent } from '../../../../components/ngx-monaco-editor/editor.component';
import { MonacoEditorLoaderService } from '../../../../components/ngx-monaco-editor/monaco-editor-loader.service';
import { ProgrammableComponent } from '../../../../components/programmable/programmable.component';
import { loadDefaultDefinitelyFromCdn } from '../../../../definition/load-definitely-from-cdn';
import { BasicColorPaletteDialog } from '../../../../dialog/basic-color-palette';
import { FacebookPostSelectorDialog } from '../../../../dialog/facebook-post-selector-dialog/facebook-post-selector.dialog';
import { LingtelliManagerDialog } from '../../../../dialog/lingtelli';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { MatTreePicker } from '../../../../material/tree-picker/tree-picker';
import {
  MatTreePickerNode,
  MatTreePickerNodeDef,
} from '../../../../material/tree-picker/tree-picker-node';
import { MatTreePickerTrigger } from '../../../../material/tree-picker/tree-picker-trigger';
import { EnsureTextLengthPipe } from '../../../../pipe/ensure-text-length.pipe';
import { ModuleTypePipe } from '../../../../pipe/module.pipe';
import { SplitPipe } from '../../../../pipe/split.pipe';
import { BotService } from '../../../../service/bot.service';
import { ExchangeService } from '../../../../service/exchange.service';
import { FacebookPostService } from '../../../../service/facebook-post.service';
import { KeywordService } from '../../../../service/keyword.service';
import { PropertyConfigService } from '../../../../service/property-config.service';
import { TagService } from '../../../../service/tag.service';
import { AddConditionCommand } from '../../command/addCondition';
import { DeleteConditionCommand } from '../../command/deleteCondition';
import { Invoker } from '../../command/invoker';
import { OrderConditionCommand } from '../../command/orderCondition';
import { ExecutorDTS } from '../../definition/executor';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { Condition, ConditionTargetType, SwitchNode } from './class';
import { OperandTranslatePipe } from './operandTranslate.pipe';
import { OperandsPipe } from './operands.pipe';
import { SwitchTypePipe } from './switchType.pipe';

@Component({
  selector: 'flow-switch-form',
  templateUrl: './form.component.html',
  styleUrls: ['form.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatCheckbox,
    FormsModule,
    MatAccordion,
    NgFor,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    NgTemplateOutlet,
    MatExpansionPanelActionRow,
    MatButton,
    MatTooltip,
    MatIconButton,
    MatIcon,
    FlexModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    MatHint,
    NgSwitch,
    NgSwitchCase,
    ProgrammableComponent,
    GosuValidatorComponent,
    DatetimePickerComponent,
    MatChipGrid,
    MatChipRow,
    MatChipRemove,
    MatChipInput,
    EditorComponent,
    MatTreePickerTrigger,
    MatTreePicker,
    MatTreePickerNodeDef,
    MatTreePickerNode,
    AsyncPipe,
    EnsureTextLengthPipe,
    ModuleTypePipe,
    SplitPipe,
    OperandsPipe,
    OperandTranslatePipe,
    SwitchTypePipe,
    LegacyAppearanceDirective,
  ],
})
export class SwitchFormComponent
  extends FormComponent<SwitchNode>
  implements AfterViewInit, OnDestroy
{
  public enableMonaco$ = this._monacoEditorLoaderService.enableMonaco$;

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public editorOptions = {
    theme: 'vs-dark',
    language: 'typescript',
    minimap: { enabled: false },
    snippetSuggestions: 'auto',
  };

  public panelIndex = 0;

  public tagTargetTypes: ConditionTargetType[] = ['profile', 'bot'];

  public noneExistTag = $localize`此標籤已刪除`;

  public deletedTagIds: number[] = [];

  public deletedTagIds$ = this._tagService.profile.showAllWithDeleted.list$
    .pipe(
      map((tags) => tags.filter((tag) => tag.deletedAt).map((tag) => tag.id)),
    )
    .subscribe((tags) => (this.deletedTagIds = tags));

  public propertyTargetTypes: ConditionTargetType[] = [
    'profile',
    'bot',
    'company',
  ];

  public colors = [
    '#E53935',
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#34495e',
    '#f1c40f',
    '#e67e22',
    '#CCCCCC',
  ];

  public switchTypes: string[] = [
    'TEXT',
    'KEYWORD',
    'DATETIME',
    'HAS_TAG',
    'HAS_PROPERTY',
    'SOURCE_CODE',
    'FILTER_PROFILE_ID',
    'FILTER_PROFILE_TYPE',
    'FILTER_PLATFORM',
    'FILTER_BOT',
    'FILTER_POST',
    'FILTER_IG_POST',
    'FILTER_POST_HASHTAG',
    'CHECK_EXCHANGE',
    'LINGTELLI',
    'BEACON_ID',
    'ELSE',
  ];

  public datetimeMethods = [
    { value: 'isSame', text: $localize` = 等於` },
    { value: 'isBefore', text: $localize` < 早於` },
    { value: 'isAfter', text: $localize` > 晚於` },
    { value: 'isSameNow', text: $localize` = 等於觸發當下` },
    { value: 'isBeforeNow', text: $localize` < 早於觸發當下` },
    { value: 'isAfterNow', text: $localize` > 晚於觸發當下` },
  ];

  public datetimePlaceholder = {
    date: $localize`比對日期`,
    time: $localize`比對時間`,
    datetime: $localize`比對日期時間`,
  };

  public judgeDatetimePlaceholder = {
    date: $localize`對照日期`,
    time: $localize`對照時間`,
    datetime: $localize`對照日期時間`,
  };

  public keywordList$ = this._keywordService.groupList$.pipe(
    map((list) =>
      list.map((keyword: any) => {
        keyword.getChildren = () => null;
        keyword.hasChildren = () => false;
        return keyword;
      }),
    ),
    shareReplay(1),
  );
  public keywordNameMap$ = this._keywordService.groupNameMap$;

  public propConfigs$ = this._propertyConfigService.all$.pipe(
    map((configs) => {
      if (configs === null) return null;
      const types = {};
      configs.map((config) => {
        if (types[config.targetType] === undefined) {
          types[config.targetType] = [];
        }
        types[config.targetType].push(config);
      });
      return types;
    }),
  );

  public constructor(
    public readonly exchangeService: ExchangeService,
    public readonly elementRef: ElementRef,
    public readonly flowService: FlowService,
    private readonly _invoker: Invoker,
    private readonly _tagService: TagService,
    private readonly _propertyConfigService: PropertyConfigService,
    private readonly _keywordService: KeywordService,
    private readonly _BotService: BotService,
    private readonly _facebookPostService: FacebookPostService,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _monacoEditorLoaderService: MonacoEditorLoaderService,
  ) {
    super(elementRef);
  }

  public ngOnDestroy() {
    this.node.checkError();
  }

  public ngAfterViewInit(): void {
    console.log(this.node);
    super.ngAfterViewInit();
    this._loadSelectedPost();
    this._loadAllExchangeMethods();
  }

  public bots$ = this._BotService.all$;

  public tagList$ = this._tagService.profile.all.tree$;
  public tagMap$ = this._tagService.profile.all.map$;
  public postNameMap$ = this._facebookPostService.postNameMap$;

  public selectProperty(key: string, properties: PropertyConfigDto[]) {
    return properties.find((prop) => prop.key == key);
  }

  public openFacebookPostDialog(condition: Condition) {
    const res = this._matConnectedDialog.open(FacebookPostSelectorDialog, {
      height: '72vh',
      width: '600px',
      data: {
        filterBotIds: this.flowService.selectedBots().map((bot) => bot.id),
        postIds: condition.postIds,
      },
      panelClass: 'custom-dialog-container',
    });
    res.afterClosed().subscribe(() => {
      condition.postIds = [...condition.postIds];
    });
  }

  public openInstagramPostDialog(condition: Condition) {
    const res = this._matConnectedDialog.open(InstagramPostSelectorDialog, {
      height: '72vh',
      width: '600px',
      data: {
        filterBotIds: this.flowService.selectedBots().map((bot) => bot.id),
        postIds: condition.postIds,
      },
      panelClass: 'custom-dialog-container',
    });
    res.afterClosed().subscribe(() => {
      condition.postIds = [...condition.postIds];
    });
  }

  public removedItem(key: string, condition: Condition, id: any) {
    if (!condition[key]) condition[key] = [];
    condition[key] = condition[key].filter((_id) => !!_id && _id != id);
    condition[key] = [...condition[key]];
  }

  public selectedItem(key: string, condition: Condition, id: any) {
    if (!condition[key]) condition[key] = [];
    let index = condition[key].indexOf(id);
    if (index == -1) {
      condition[key].push(id);
    } else {
      condition[key].splice(index, 1);
    }
    condition[key] = [...condition[key]];
  }

  public selectNotFoundNode(key: 'keyword' | 'tag') {
    const content =
      key == 'keyword'
        ? $localize`查無關鍵字：請至關鍵字管理新增詞組`
        : $localize`查無標籤：請至標籤管理新增標籤`;
    const redirectLink = key == 'keyword' ? '/keyword' : '/tag';
    const buttonText =
      key == 'keyword' ? $localize`前往關鍵字管理` : $localize`前往標籤管理`;
    this._matConnectedDialog.open(RedirectPageDialog, {
      autoFocus: false,
      data: {
        content,
        buttonText,
        redirectLink,
      },
    });
  }

  // 新增條件
  public addCondition() {
    let condition = new Condition();
    condition.name = $localize`條件 ` + (1 + this.node.conditions.length);
    this._invoker.do(new AddConditionCommand(this.node, condition));

    let idx = this.node.conditions.length - 1;
    this._selectColor(idx, '#CCCCCC');

    setTimeout(() => {
      let newElement = document.getElementById(`condition${idx}`);
      newElement.scrollIntoView({ block: 'nearest' });
    }, 100);
  }

  // #region 設定條件顏色
  private _selectColor(index: number, color: string) {
    this.node.conditions[index].color = color;
    this.showColorPicker[index] = false;
  }
  // #endregion

  public deleteCondition(index: number) {
    this._invoker.do(
      new DeleteConditionCommand(
        this.flowService,
        this.node,
        this.node.conditions[index],
      ),
    );
  }

  public async setOrderCondition(index: number, action: number) {
    let tar = limitRange(0, index + action, this.node.conditions.length - 1);
    if (index == tar) return false;
    this._invoker.do(
      new OrderConditionCommand(
        this.node,
        this.node.conditions[index],
        this.node.conditions[tar],
      ),
    );
    await delay(1);
    this.panelIndex = tar;
    return true;
  }

  public addText(condition: Condition, event: any) {
    let value = (event.value || '').trim();
    let values = condition.key.split(',');
    if (value && !values.includes(value)) {
      condition.key = values
        .concat(value)
        .filter((s) => !!s)
        .join(',');
    }
    event.input.value = '';
  }

  public removeText(condition: Condition, value: string) {
    condition.key = condition.key
      .split(',')
      .filter((s) => !!s && s != value)
      .join(',');
  }

  public addItem(
    condition: Condition,
    key: string,
    event: any,
    type = 'string',
  ) {
    if (!(condition[key] instanceof Array)) condition[key] = [];
    let value = (event.value || '').trim();
    if (type == 'number') {
      value = parseInt(value);
      if (isNaN(value)) {
        event.input.value = '';
        return;
      }
    }
    if (!condition[key].includes(value)) condition[key].push(value);
    event.input.value = '';

    condition[key] = [...condition[key]];
  }

  public removeItem(condition: Condition, key: string, value: string) {
    if (!(condition[key] instanceof Array)) condition[key] = [];
    condition[key] = condition[key].filter((s) => !!s && s != value);

    condition[key] = [...condition[key]];
  }

  public addHashTag(condition: Condition, event: any) {
    if (!(condition.hashTags instanceof Array)) condition.hashTags = [];
    let value: string = (event.value || '').trim();
    if (!value) {
      event.input.value = '';
      return;
    }
    if (!['#', '＃'].includes(value[0])) value = '#' + value;
    if (
      new RegExp(/^([#＃])[\p{L}|\d]+$/giu, 'giu').test(value) &&
      !condition.hashTags.includes(value)
    ) {
      condition.hashTags = condition.hashTags.concat(value).filter((s) => !!s);
    }
    event.input.value = '';
    condition.hashTags = [...condition.hashTags];
  }

  public checkboxEvent(
    condition: Condition,
    key: string,
    value: any,
    event: MatCheckboxChange,
  ) {
    if (!(condition[key] instanceof Array)) condition[key] = [];
    if (event.checked) {
      if (!condition[key].includes(value)) condition[key].push(value);
    } else {
      condition[key] = condition[key].filter((s) => s != value);
    }
    condition[key] = [...condition[key]];
  }

  public async showColorPicker(
    elementRef: ElementRef<HTMLElement>,
    index: number,
  ) {
    const dialogRef = this._matConnectedDialog.open(BasicColorPaletteDialog, {
      elementRef,
      panelClass: 'dialog-container-p0',
      data: { color: this.node.conditions[index].color },
    });

    const color = await firstValueFrom(dialogRef.afterClosed());
    if (!color) return;

    this._selectColor(index, color);
  }

  private async _loadSelectedPost() {
    if (!this.node) return;
    let postIds: string[] = [];
    for (let condition of this.node.conditions) {
      postIds = postIds.concat(condition.postIds || []);
    }
    postIds = postIds.filter((id) => !!id);
    await this._facebookPostService.loadPostByIds(postIds);
  }

  private async _loadAllExchangeMethods() {
    for (let condition of this.node.conditions) {
      await this.loadExchangeMethods(condition);
    }
  }

  public async openLingtelliDialog(condition: Condition) {
    await firstValueFrom(
      this._matConnectedDialog
        .open(LingtelliManagerDialog, {
          height: '75vh',
          width: '720px',
          data: { condition },
          panelClass: 'custom-dialog-container',
        })
        .afterClosed(),
    );
  }

  public async loadExchangeMethods(condition: Condition) {
    if (!condition.exchangeId) {
      return;
    }

    const exchangeId = parseInt(condition.exchangeId, 10);
    if (isNaN(exchangeId)) {
      return;
    }

    const exchange = await ExchangeModel.fetch(exchangeId);
    condition.methods = exchange.methods;
  }

  public split(value: any) {
    return value ? value.split(',').map((v) => parseInt(v)) : [];
  }

  public async parseTagIds(condition: Condition, tagIds: (number | string)[]) {
    const availableTags = await firstValueFrom(
      this._tagService.profile.all.list$,
    );
    const availableTagIds = availableTags.map((tag) => tag.id);

    // 刪除不存在的 tagId
    for (let i = 0; i < tagIds.length; i++) {
      const tagId = tagIds[i];
      if (typeof tagId !== 'number') continue;
      if (availableTagIds.includes(tagId)) continue;
      tagIds.splice(i, 1);
      i--;
    }

    condition.tagIds = tagIds;
  }

  public changeDatetimeMethod(condition: Condition) {
    if (condition.datetimeMethod?.includes('Now'))
      condition.judgeDatetime =
        "${moment().format('" + condition.datetimeFormat + "')}";
    else delete condition.judgeDatetime;
  }

  public async onMonacoEditorInit() {
    const ts = (window as any).monaco.languages.typescript.typescriptDefaults;
    ts.setExtraLibs([]);
    ts.addExtraLib(ExecutorDTS, `Executor.d.ts`);
    await loadDefaultDefinitelyFromCdn(ts);
  }
}
