<div class="bg-gray-800 rounded p-4 w-full grid grid-cols-3 gap-x-4 gap-y-2">
  <mat-form-field class="col-span-1 mb-3" legacyAppearance>
    <mat-label i18n>Method</mat-label>
    <mat-select [(ngModel)]="node().method">
      <mat-option value="get">GET</mat-option>
      <mat-option value="post">POST</mat-option>
      <mat-option value="put">PUT</mat-option>
      <mat-option value="delete">DELETE</mat-option>
      <mat-option value="head">HEAD</mat-option>
      <mat-option value="options">OPTIONS</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-span-2 mb-3" legacyAppearance>
    <mat-label i18n>API路徑</mat-label>
    <input matInput [(ngModel)]="path" maxlength="100" />
    <mat-hint>
      @if (node().path?.length; as length) {
        <span i18n> 字數剩餘 {{ 100 - length }} 個字 </span>
      } @else {
        <span i18n> 字數最多 100 個字 </span>
      }
    </mat-hint>
  </mat-form-field>

  @if (node().path && trackingProperties()) {
    @if (bots().length > 0) {
      @if (node().trackingUrlParams.length == 0) {
        @for (bot of bots(); track $index) {
          <flow-bot-url
            [urlPath]="urlPath()"
            [name]="bot.name"
            [botId]="bot.id"
          >
          </flow-bot-url>
        }
      } @else {
        @for (params of node().trackingUrlParams; track $index) {
          <flow-utm-tracking-form
            [node]="node()"
            [path]="path()"
            [index]="$index"
            [trackingUrlParams]="params"
            [utmTrackingProperties]="utmTrackingProperties()"
            [customerTrackingProperties]="customerTrackingProperties()"
            [untrackParamsTooltip]="untrackParamsFormTooltip"
            [utmTrackingTooltip]="utmTrackingTooltip"
            [customerTrackingTooltip]="customerTrackingTooltip"
            (removeUtmTrackingCodeForm)="removeUtmTrackingCodeForm($index)"
          >
          </flow-utm-tracking-form>
        }
      }

      <div class="col-span-3 text-center text-gray-500 text-sm p-4">
        <button mat-button color="primary" (click)="addUtmTrackingCodeForm()">
          <mat-icon>add</mat-icon>
          @if (node().trackingUrlParams.length == 0) {
            <span i18n>啟用 UTM 追蹤連結功能</span>
          } @else {
            <span i18n>新增 UTM 追蹤連結</span>
          }
        </button>
      </div>
    } @else {
      <flow-bot-url name="" [botId]="0" [urlPath]="urlPath()"> </flow-bot-url>
    }
  }
</div>

<div class="bg-gray-800 rounded p-4 w-full grid grid-cols-3 gap-x-4 gap-y-2">
  <mat-form-field class="col-span-3" legacyAppearance>
    <mat-label i18n>白名單網域</mat-label>
    <input matInput [(ngModel)]="node().whitelist" />
  </mat-form-field>
</div>

<div class="bg-gray-800 rounded p-4 w-full grid grid-cols-3 gap-x-4 gap-y-2">
  <mat-checkbox class="col-span-1" [(ngModel)]="node().isAutoReply" i18n>
    自動回應
  </mat-checkbox>

  <mat-checkbox class="col-span-1" [(ngModel)]="node().isOAuth" i18n>
    檢查登入狀態
  </mat-checkbox>

  <mat-checkbox class="col-span-1" [(ngModel)]="node().generateForm" i18n>
    產生對應表單
  </mat-checkbox>
</div>

@if (node().generateForm) {
  <div class="bg-gray-800 rounded p-4 w-full">
    <h3 i18n class="mb-2">對應表單</h3>

    <div i18n id="form-config-description" class="hidden">
      您可以在此設定表單的欄位，並且選擇對應的變數名稱。
    </div>

    <table
      mat-table
      [dataSource]="node().formConfig"
      class="mb-2"
      aria-describedby="form-config-description"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef i18n>欄位名稱</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>
      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef i18n>變數名稱</th>
        <td mat-cell *matCellDef="let row">{{ row.key }}</td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef i18n>型態</th>
        <td mat-cell *matCellDef="let row">{{ row.type | fieldType }}</td>
      </ng-container>

      <ng-container matColumnDef="default">
        <th mat-header-cell *matHeaderCellDef i18n>預設值</th>
        <td mat-cell *matCellDef="let row">{{ row.default }}</td>
      </ng-container>
      <ng-container matColumnDef="tool">
        <th mat-header-cell *matHeaderCellDef class="tar" i18n>操作</th>
        <td mat-cell *matCellDef="let row; let index = index" class="w-[200px]">
          <button
            mat-icon-button
            (click)="moveFormConfig(index, -1)"
            [disabled]="index === 0"
          >
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="moveFormConfig(index, 1)"
            [disabled]="index === node().formConfig.length - 1"
          >
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>

          <button mat-icon-button (click)="editFormConfig(row)">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button (click)="removeFormConfig(index)">
            <mat-icon>close</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="['name', 'key', 'type', 'default', 'tool']"
      ></tr>
      <tr
        mat-row
        *matRowDef="
          let row;
          columns: ['name', 'key', 'type', 'default', 'tool']
        "
      ></tr>
    </table>

    <div class="text-center">
      <button mat-button color="primary" (click)="addFormConfig()" i18n>
        增加欄位
      </button>
    </div>
  </div>
}

<ng-template #utmTrackingTooltip>
  <div class="p-2 flex flex-row gap-2 items-center leading-5">
    <mat-icon class="!size-[18px] !text-[18px]"> info </mat-icon>
    <div i18n class="w-[400px]">
      您可以在「屬性設定」的「好友屬性」中找到下列 5 個 UTM
      屬性，透過屬性的「追蹤代碼保存設定」您可以決定資料的保存時長。
    </div>
  </div>
</ng-template>

<ng-template #customerTrackingTooltip>
  <div class="p-2 flex flex-row gap-2 items-center leading-5">
    <mat-icon class="!size-[18px] !text-[18px]"> info </mat-icon>
    <div i18n class="w-[400px]">
      您可以在「屬性設定」的「好友屬性」中找到下列屬性，透過屬性的「追蹤代碼保存設定」您可以決定資料的保存時長，如果您設定為「不啟用」，那麼該屬性就不會在此介面出現。
    </div>
  </div>
</ng-template>

<ng-template #untrackParamsFormTooltip>
  <div class="p-2 flex flex-row gap-2 items-center leading-5">
    <mat-icon class="!size-[18px] !text-[18px]"> info </mat-icon>
    <div i18n class="w-[400px]">
      客製化專案，可以透過該功能來設定最終要給出去的網址
    </div>
  </div>
</ng-template>
