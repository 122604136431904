import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { PromotionChannelModel } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { MatTooltipModule } from '@ay-gosu/ui/material/tooltip';
import { Area, Content } from '@ay/bot';
import { firstValueFrom, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GosuValidatorComponent } from '../../../components/gosu-validator/gosu-validator.component';
import { ImagePickerComponent } from '../../../components/image-picker/image-picker.component';
import { UrlBuilderDialog } from '../../../components/url-builder/url-builder.component';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { PackageFactoryService } from '../../package-factory.service';
import { Package } from '../../package.class';
import { PosterMessage } from '../poster.message';

@Component({
  selector: 'ms-poster-creator-dialog',
  templateUrl: './creator.dialog.html',
  styleUrls: ['./creator.dialog.scss'],
  standalone: true,
  imports: [
    ImagePickerComponent,
    NgFor,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    NgIf,
    MatOption,
    MatInput,
    MatButton,
    AsyncPipe,
    LegacyAppearanceDirective,
    GosuValidatorComponent,
    MatIconButton,
    MatSuffix,
    MatIcon,
    MatTooltipModule,
  ],
})
export class PosterCreatorDialog {
  public poster: Content.Poster;

  public message: PosterMessage;

  public package: Package;

  public promotions$ = of([]).pipe(
    mergeMap((_) =>
      PromotionChannelModel.list(1, 100).then((res) =>
        res.data.filter((row) => row.type === 'SHARE_MESSAGE'),
      ),
    ),
  );

  public constructor(
    public readonly packageFactoryService: PackageFactoryService,
    private readonly _matDialogRef: MatDialogRef<Content.Poster>,
    @Inject(MAT_DIALOG_DATA)
    data: { poster: Content.Poster; message: PosterMessage; package: Package },
    private readonly _matDialog: MatConnectedDialog,
  ) {
    this.poster = data.poster;
    this.message = data.message;
    this.package = data.package;
  }

  public submit() {
    if (this.poster.content === '') {
      this._matDialogRef.close();
    } else {
      this._matDialogRef.close(this.poster);
    }
  }

  public async openUrlBuilderDialog(area: Area.Uri) {
    const dialogRef = this._matDialog.open(UrlBuilderDialog, {
      data: { url: area.content, from: 'poster' },
    });

    const response = await firstValueFrom(dialogRef.afterClosed());
    if (!response) return;
    area.content = response;
  }
}
