import { Component, computed, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { AsyncJobModule, asyncJob } from '@ay-gosu/ui/common/async-job';
import { MatTooltipModule } from '@ay-gosu/ui/material/tooltip';
import QRCodeStyling from 'qr-code-styling';
import { BotService } from '../../../../../../service/bot.service';
import { ClipboardService } from '../../../../../../service/clipboard.service';
import { KonamiCode } from '../../../../../../service/konami-code.service';
@Component({
  selector: 'flow-bot-url',
  templateUrl: './bot-url.component.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatButtonModule,
    AsyncJobModule,
    MatMenuModule,
  ],
  host: {
    class: 'grid col-span-3 grid-cols-3 gap-4',
  },
})
export class BotUrlComponent {
  public name = input.required<string>();

  // 包含整個 URL 的路徑 ，但網址中可能會夾帶 [botId]，所以需要再將 [botId] 替換回成實際的編號
  public urlPath = input.required<string>();

  public botId = input.required<number>();

  public bot = asyncJob((botId) => this._botService.get(botId), this.botId);

  public normalUrl = computed(() => {
    if (this.name() == '') return '';
    return this.urlPath().replace('[botId]', this.botId().toString());
  });

  public urls$ = asyncJob(
    (bot, normalUrl) => {
      const urls: {
        label: string;
        url: string;
      }[] = [];

      urls.push({ label: '一般網址', url: normalUrl.toString() });

      if (this.konamiCode.isUnlock()) {
        const liffV1Prefix = 'https://line.me/R/app/';
        const liffV2Prefix = 'https://liff.line.me/';
        const liffProperties = [
          {
            label: 'LIFF v1 滿版',
            key: 'fullLiffId',
            prefix: liffV1Prefix,
          },
          {
            label: 'LIFF v1 70%',
            key: 'tallLiffId',
            prefix: liffV1Prefix,
          },
          {
            label: 'LIFF v1 50%',
            key: 'compareLiffId',
            prefix: liffV1Prefix,
          },
          {
            label: 'LIFF v2 滿版',
            key: 'fullLiffId',
            prefix: liffV2Prefix,
          },
          {
            label: 'LIFF v2 70%',
            key: 'tallLiffId',
            prefix: liffV2Prefix,
          },
          {
            label: 'LIFF v2 50%',
            key: 'compareLiffId',
            prefix: liffV2Prefix,
          },
        ];

        liffProperties.forEach((liff) => {
          const liffId = bot.property[liff.key] as string;
          if (!liffId) return;

          const liffUrl = new URL(liff.prefix + liffId);
          liffUrl.searchParams.set('redirectUrl', normalUrl);
          urls.push({ label: liff.label, url: liffUrl.toString() });
        });
      }

      return urls;
    },
    this.bot,
    this.normalUrl,
  );

  public constructor(
    private readonly _clipboardService: ClipboardService,
    private readonly _botService: BotService,
    public readonly konamiCode: KonamiCode,
  ) {}

  public copy(url: string) {
    this._clipboardService.copy(url);
  }

  public downloadQrCode(url: string) {
    const qrCode = new QRCodeStyling({
      data: url,
      width: 256,
      height: 256,
      margin: 0,
      type: 'canvas',
      imageOptions: {
        hideBackgroundDots: true,
      },
    });
    const timestamp = new Date().getTime();
    qrCode.download({
      name: `${this.name()}-${timestamp}`,
      extension: 'png',
    });
  }
}
