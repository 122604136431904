<div class="header" i18n>編輯 UTM 參數</div>

<div class="container w-[600px] !p-4 flex flex-col gap-4" [formGroup]="form">
  <div class="bg-gray-33 text-sm w-full p-4 rounded mb-5">
    @if (from() === 'rich-menu') {
      <span i18n>
        透過設定 UTM (Urchin Tracking Module)
        參數，您可以分析流量來源，知道流量是從哪裡來的。如果終端使用者的 UTM
        屬性已有資料，我們仍會以您此處設定的 UTM 參數為主，詳細說明請見
      </span>
    } @else {
      <span i18n>
        透過設定 UTM (Urchin Tracking Module)
        參數，您可以分析流量來源，知道流量是從哪裡來的，詳細說明請見
      </span>
    }
    <a
      i18n
      class="text-primary"
      target="_name"
      href="https://u.gosu.bar/utm-setup"
    >
      教學文章
    </a>
  </div>

  <mat-form-field class="w-full">
    <mat-label i18n>網址</mat-label>
    <input matInput formControlName="url" />
  </mat-form-field>

  <div i18n>UTM 參數設定</div>
  <div class="grid grid-cols-3 gap-4" formGroupName="utmParams">
    @for (config of utmPropertyConfigs; track $index) {
      <mat-form-field>
        <mat-label i18n>{{ config.name }}</mat-label>
        <input matInput [formControlName]="config.key" />
      </mat-form-field>
    }
  </div>

  @if (customizePropertyConfigs.length > 0) {
    <div i18n>自訂屬性設定</div>
    <div class="grid grid-cols-3 gap-4" formGroupName="customizeParams">
      @for (config of customizePropertyConfigs; track $index) {
        <mat-form-field>
          <mat-label i18n>{{ config.name }}</mat-label>
          <input matInput [formControlName]="config.key" />
        </mat-form-field>
      }
    </div>
  }

  @if (konamiCode.unlock$ | async) {
    <div i18n>自訂參數</div>
    @for (param of params.controls; track $index) {
      <div [formGroup]="param" class="flex w-full flex-row gap-4 !items-center">
        <mat-form-field class="flex-1">
          <mat-label i18n>Key</mat-label>
          <input matInput formControlName="key" />
        </mat-form-field>

        <mat-form-field class="flex-1">
          <mat-label i18n>Value</mat-label>
          <input matInput formControlName="value" />
        </mat-form-field>

        <button mat-icon-button color="warn" (click)="removeParam($index)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    }

    <div class="flex flex-row gap-4 !justify-center w-full">
      <button mat-button color="primary" (click)="addParam()">
        <mat-icon>add</mat-icon>
        <span i18n>新增參數</span>
      </button>
    </div>
  }
</div>

<div class="footer flex flex-row gap-4 !justify-end">
  <button mat-button (click)="close()">取消</button>
  <button mat-button color="primary" (click)="confirm()">確定</button>
</div>
