import { effect, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountDto, AccountModel, CompanyModel } from '@ay-gosu/server-shared';
import { asyncJob } from '@ay-gosu/ui/common/async-job';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import jwtDecode from 'jwt-decode';
import { firstValueFrom, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomerReminder } from '../customer-reminder';
import { ErrorDialog } from '../dialog/basic';
import { ConnectionService } from './connection.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  // 這個 token 尚未被驗證過，可能是不正確的，不可以直接取用
  // undefined 代表尚未執行透過 localStorage 自動登入
  // null 代表登入失敗或沒有 localStorage
  private _token = signal<string>(undefined);

  public token$ = asyncJob((token) => token, this._token);

  public rxToken$ = toObservable(this._token);

  // 如果是登入中，狀態會是 LOADING (undefined)
  // 如果是沒有登入，狀態會是 EMPTY (null)
  // 如果是登入成功，狀態會是 SUCCESS
  // 如果過程中發生任何異常，系統會自動登出
  public payload$ = asyncJob(
    async (status, token) => {
      try {
        // 等待 LocalStorageAutoLogin 設定為 null or token
        if (token === undefined) return undefined;

        // 連線中
        if (status === false) return undefined;

        // 沒有登入
        if (token === null) return null;

        let decoded = jwtDecode<AccountDto & { iat: number; exp: number }>(
          token,
        );

        // token 已經過期
        if (decoded.exp && decoded.exp < Date.now() / 1000) {
          console.warn('token 已經過期，自動登出');
          await this.logout(true);
          return undefined;
        }

        // 當網頁斷線、在手機上切換分頁、APP時，會導致 WebSocket 斷線，這時候會需要重新透過 token 重新登入
        // 為了避免登入後又馬上觸發 asyncJob 重新登入，這裡會多加一個 3 秒的緩衝時間
        if (token && (!decoded.iat || decoded.iat < Date.now() / 1000 - 3)) {
          console.warn('重新展延 token');
          try {
            const renewedToken = await AccountModel.loginViaToken(token);
            if (renewedToken !== token) {
              this._token.set(renewedToken);
              return undefined;
            }
          } catch (error) {
            await this.logout();
            return undefined;
          }
        }

        // token 是有期限的（各個組織可以獨立設定）
        if (decoded.exp) {
          if (this._autoLogoutTimeout) {
            window.clearTimeout(this._autoLogoutTimeout);
          }

          const ms = decoded.exp * 1000 - Date.now();
          if (ms < Math.pow(2, 31) - 1) {
            // setTimeout 最多指支援 2^32 - 1 ms ~24.8 day
            this._autoLogoutTimeout = window.setTimeout(async () => {
              this._autoLogoutTimeout = null;
              console.warn('自動登出期限已到，系統自動登出');
              await this.logout(true);
            }, ms);
          }
        }

        return decoded;
      } catch (error) {
        await this.logout();
        return null;
      }
    },
    toSignal(this._connectionService.status$),
    this._token,
  );

  // 使用者已登入，但有[可能]還沒有選擇組織
  // 如果是登入中，狀態會是 LOADING (undefined)
  // 如果是沒有登入，狀態會是 EMPTY (null)
  // 如果是登入成功，不管有沒有選擇組織，狀態會是 SUCCESS
  // 如果過程中發生任何異常，系統會自動登出
  public accountWithoutCompany$ = asyncJob((token) => {
    if (!token) return undefined;
    return token;
  }, this.payload$);

  public rxAccountWithoutCompany$ = toObservable(
    this.accountWithoutCompany$.data,
  );

  // 如果是 登入中 或 還沒選擇組織 狀態會是 LOADING (undefined)
  // 如果是沒有登入，狀態會是 EMPTY (null)
  // 如果是登入成功，並選擇好組織，狀態會是 SUCCESS
  // 如果過程中發生任何異常，系統會自動登出
  public account$ = asyncJob((token) => {
    if (!token || !token.companyId) return undefined;
    return token;
  }, this.payload$);

  public rxAccount$ = toObservable(this.account$.data);

  // 是否為超級管理員(整個站台的管理員)
  public isSuperAdmin$ = asyncJob(
    (account) => account.isSuperAdmin,
    this.account$,
  );

  public rxIsSuperAdmin$ = toObservable(this.isSuperAdmin$.data);

  public company$ = asyncJob(async (account) => {
    if (!account) return undefined;
    const company = await CompanyModel.fetch(account.companyId);
    return company;
  }, this.account$);

  public rxCompany$ = toObservable(this.company$.data);

  private _autoLogoutTimeout: number;

  public isLoading$ = new Subject<boolean>();

  public constructor(
    private readonly _connectionService: ConnectionService,
    private readonly _router: Router,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _customerReminder: CustomerReminder,
    private readonly _matDialog: MatDialog,
  ) {
    this._processLocalStorageAutoLogin();
    this._customerReminder.tokenService = this;
  }

  public async isLoggedIn(): Promise<boolean> {
    try {
      const token = await this.payload$.toPromise();
      return Boolean(token);
    } catch (error) {
      return false;
    }
  }

  public async login(account: string, password: string): Promise<string> {
    let token = await AccountModel.login(account, password);
    if (!token) throw $localize`帳號或密碼錯誤`;

    this._token.set(token);

    return token;
  }

  public async selectCompany(companyId: number): Promise<string> {
    const payload = await this.payload$.toPromise();
    if (!payload) throw $localize`尚未登入，無法選擇組織`;

    let renewedToken = await AccountModel.selectCompany(companyId);
    if (!payload) throw $localize`選擇組織時發生錯誤`;

    this._token.set(renewedToken);
    return renewedToken;
  }

  public async loginViaToken(token: string): Promise<string> {
    await this._connectionService.awaitConnected();
    try {
      const renewedToken = await AccountModel.loginViaToken(token);
      this._token.set(renewedToken);
      return renewedToken;
    } catch (error) {
      this._token.set(null);
      throw error;
    }
  }

  public async logout(showDialog = false) {
    if (showDialog) {
      await this._showLogoutDialog();
    }
    await AccountModel.logout().catch(() => {});
    this._token.set(null);
    this._matDialog.closeAll();
    this._router.navigate(['login', 'form']);
  }

  private async _showLogoutDialog() {
    this._customerReminder.isActive = false;
    this._matConnectedDialog.closeAll();

    const matDialogRef = this._matConnectedDialog.open(ErrorDialog, {
      disableClose: true,
    });

    const dialog = matDialogRef.componentInstance;
    dialog.content = '系統已登出，請重新登入';
    dialog.buttons = [
      {
        label: '重新登入',
        type: 'raised',
        color: 'primary',
        result: 'LOGOUT',
      },
    ];
    await firstValueFrom(matDialogRef.afterClosed());
  }

  private _processLocalStorageAutoLogin() {
    // 內嵌私訊的登入狀態跟直接登入後台的登入狀態是分開的，不會互相影響
    // 所以如果是內嵌私訊，則不要處理 localStorage
    if (location.pathname.includes('embedded-private-message')) {
      this._token.set(null);
      return;
    }

    // 透過 localStorage 自動登入
    const key = environment.loginTokenKey;
    const token = localStorage.getItem(key);
    if (token) {
      this.loginViaToken(token).catch(() => {
        localStorage.removeItem(key);
      });
    } else {
      this._token.set(null);
    }

    // 將 token 存回 localStorage
    let prevToken = token;
    effect(() => {
      let token = this._token();
      if (token === undefined) return;

      if (token === prevToken) return;
      prevToken = token;
      if (token) {
        localStorage.setItem(key, token);
      } else {
        localStorage.removeItem(key);
      }
    });
  }
}
