import { AsyncPipe } from '@angular/common';
import {
  Component,
  computed,
  effect,
  input,
  output,
  signal,
  TemplateRef,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AsyncJobModule } from '@ay-gosu/ui/common/async-job';
import { MatTooltipModule } from '@ay-gosu/ui/material/tooltip';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { KonamiCode } from '../../../../../../service/konami-code.service';
import { FlowService } from '../../../../flow.service';
import { WebhookEventNode } from '../../class';
import { BotUrlComponent } from '../bot-url/bot-url.component';

@Component({
  selector: 'flow-utm-tracking-form',
  templateUrl: './utm-tracking-form.component.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
    BotUrlComponent,
    MatButtonModule,
    AsyncJobModule,
    AsyncPipe,
  ],
  host: {
    class: 'grid col-span-3 grid-cols-3 gap-4',
  },
})
export class UtmTrackingFormComponent {
  public node = input.required<WebhookEventNode>();

  // API 的路徑，例如：https://1.api.gosu.bar/customize/test 中的 test
  public path = input.required<string>();

  public ready = signal(false);

  public trackingUrlParams = input.required<{ [key: string]: string }>();

  public untrackParamsForm = new FormArray<FormGroup>([]);

  public form = new FormGroup<{
    [key: string]: AbstractControl;
  }>({
    untrackParamsForm: this.untrackParamsForm,
  });

  public index = input.required<number>();

  public utmTrackingProperties =
    input.required<{ name: string; key: string }[]>();

  public customerTrackingProperties =
    input.required<{ name: string; key: string }[]>();

  public utmTrackingTooltip = input.required<TemplateRef<any>>();

  public customerTrackingTooltip = input.required<TemplateRef<any>>();

  public untrackParamsTooltip = input.required<TemplateRef<any>>();

  public removeUtmTrackingCodeForm = output<number>();

  public formValue = toSignal(this.form.valueChanges);

  public bots = this._flowService.selectedBots();

  public urlPath = computed(() => {
    const path = this.path();
    if (!path) return '';

    const params = this.formValue();

    const altBotId = '____bot_id____'; // 由於 URL 不能包含 [botId]，所以使用這個字串代替，再回傳時再替換回 [botId]

    const normalUrl = environment.customizeUrl
      .replace('[botId]', altBotId)
      .replace('[event]', path);

    const url = new URL(normalUrl);

    for (const [key, value] of Object.entries(params)) {
      if (!value) continue;
      if (key === 'untrackParamsForm') continue;
      url.searchParams.set(key, value as string);
    }

    for (const formGroup of this.untrackParamsForm.controls) {
      const key = formGroup.get('key')?.value;
      const value = formGroup.get('value')?.value;
      if (!key || !value) continue;
      url.searchParams.set(key, value as string);
    }

    return url.toString().replace(altBotId, '[botId]');
  });

  public constructor(
    public readonly konamiCode: KonamiCode,
    private readonly _flowService: FlowService,
  ) {
    effect(
      () => {
        this.ready.set(false);

        const params = this.trackingUrlParams();

        const properties = [
          ...this.utmTrackingProperties(),
          ...this.customerTrackingProperties(),
        ];

        properties.forEach((property) => {
          let control = this.form.get(property.key);

          if (!control) {
            control = new FormControl('', [Validators.maxLength(100)]);
            this.form.addControl(property.key, control);
          }

          control.setValue(params[property.key] || '');
        });

        // 找出未定義的參數
        this.untrackParamsForm.clear();
        Object.keys(params).forEach((key) => {
          const exists = properties.find((property) => property.key === key);
          if (exists) return;

          const formGroup = new FormGroup({
            key: new FormControl(key),
            value: new FormControl(params[key]),
          });

          this.untrackParamsForm.push(formGroup);
        });

        this.ready.set(true);
      },
      { allowSignalWrites: true },
    );

    this.form.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
      const params = this.trackingUrlParams();

      const usedKeys = [];
      const values = this.form.value;
      for (const [key, value] of Object.entries(values)) {
        if (!value) continue;
        if (key === 'untrackParamsForm') continue;
        params[key] = value as string;
        usedKeys.push(key);
      }

      for (const formGroup of this.untrackParamsForm.controls) {
        const key = formGroup.get('key')?.value;
        if (!key) continue;
        const value = formGroup.get('value')?.value;
        params[key] = value;
        usedKeys.push(key);
      }

      Object.keys(params).forEach((key) => {
        if (!usedKeys.includes(key)) {
          delete params[key];
        }
      });
    });
  }

  public addUnknownParam() {
    this.untrackParamsForm.push(
      new FormGroup({
        key: new FormControl(''),
        value: new FormControl(''),
      }),
    );
  }
}
