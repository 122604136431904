import { ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterContentInit, Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatChipGrid,
  MatChipInput,
  MatChipRemove,
  MatChipRow,
} from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { BotDto, RichMenuDto, RichMenuModel } from '@ay-gosu/server-shared';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import urlJoin from 'url-join';
import { filterList } from '../../../../../util/filter-list';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { BotPipe } from '../../../../pipe/bot.pipe';
import { BotService } from '../../../../service/bot.service';
import { ConnectionService } from '../../../../service/connection.service';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { RichMenuNode } from './class';

@Component({
  selector: 'flow-rich-menu-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatCheckbox,
    FormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    MatChipGrid,
    MatChipRow,
    MatIcon,
    MatChipRemove,
    MatChipInput,
    MatHint,
    MatIconButton,
    MatInput,
    AsyncPipe,
    BotPipe,
    LegacyAppearanceDirective,
  ],
})
export class RichMenuFormComponent
  extends FormComponent<RichMenuNode>
  implements AfterContentInit
{
  public actions: { label: string; value: string }[] = [
    { value: 'link', label: $localize`給予選單` },
    { value: 'unlink', label: $localize`收回選單` },
  ];

  public userType = '';

  public get isCodeMode(): boolean {
    return (
      this.node && this.node.richMenuId && this.node.richMenuId.includes('${')
    );
  }

  public readonly separatorKeysCodes: number[] = [ENTER];

  public bots$: Observable<BotDto[]> = this._botService.all$.pipe(
    filterList(
      (bot) =>
        ['LineMessagingAPI', 'LineModuleChannel'].includes(bot.type) &&
        this._flow.selectedBots &&
        !!this._flow.selectedBots().find((_bot) => _bot.id === bot.id),
    ),
    shareReplay(1),
  );

  public botIds$: Observable<number[]> = this.bots$.pipe(
    map((bots) => (bots ? bots.map((bot) => bot.id) : null)),
    shareReplay(1),
  );

  public richMenus: RichMenuDto[] = [];

  public constructor(
    public elementRef: ElementRef,
    private _flow: FlowService,
    private _botService: BotService,
    private _connectionService: ConnectionService,
  ) {
    super(elementRef);
  }

  public get profileIds(): string[] {
    let value: string = (this.node.profileId || '').trim();
    if (value.startsWith('${')) return [value];
    return value.split(',').filter((s) => !!s);
  }

  public addProfileId(event: any) {
    let value: string = (event.input.value || '').trim();
    event.input.value = '';
    if (value === '＊' || value === '*') {
      this.node.profileId = '*';
    } else {
      if (isNaN(parseInt(value)) && !value.startsWith('${')) return;
      this.node.profileId = this.profileIds
        .concat(value)
        .filter((str) => str != '*')
        .join(',');
    }
  }

  public removeProfileId(input: any) {
    let value = (input || '').trim();
    this.node.profileId = this.profileIds
      .filter((id) => id !== value)
      .join(',');
  }

  public ngAfterContentInit() {
    this.getRichMenuList();

    this.userType =
      !this.node.profileId || this.node.profileId == '*'
        ? this.node.profileId || ''
        : 'profileId';
  }

  public async getRichMenuList() {
    if (!this.node.botId) return;
    let menus = await RichMenuModel.getList(this.node.botId);
    if (!menus) return;
    this.richMenus = menus;

    if (!this.isCodeMode) {
      this.setNodeRichMenu();
      if (!this.node.richMenu) {
        this.node.richMenuId = '';
      }
    }
  }

  public setNodeRichMenu() {
    const exist = this.richMenus.find(
      (richMenu) => richMenu.richMenuId === this.node.richMenuId,
    );
    this.node.richMenu = exist;
  }

  public getRichMenuImageUrl(richMenuId: string) {
    if (!richMenuId || !this.node.botId) return '';
    const botId = this.node.botId;

    return urlJoin(
      this._connectionService.server,
      `/richmenu/image?botId=${botId}&richMenuId=${richMenuId}`,
    );
  }
}
