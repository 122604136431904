import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import isEqual from 'lodash/isEqual';
import { combineLatest, fromEvent, of } from 'rxjs';
import {
  bufferCount,
  filter,
  map,
  mergeMap,
  shareReplay,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class KonamiCode {
  public konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];

  private noPassword$ = of(environment.noPassword);

  private enterKonamiCode$ = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
    filter((e) => e.keyCode == this.konamiCode[0]),
    mergeMap((e) =>
      fromEvent<KeyboardEvent>(window, 'keydown').pipe(
        map((e) => e.keyCode),
        bufferCount(this.konamiCode.length - 1),
        take(1),
        tap((e) => e.unshift(this.konamiCode[0])),
      ),
    ),
    filter((e) => isEqual(e, this.konamiCode)),
    take(1),
    map(() => true),
    startWith(false),
  );

  public unlock$ = combineLatest([
    this.enterKonamiCode$,
    this.noPassword$,
    this._tokenService.rxIsSuperAdmin$,
  ]).pipe(
    map(
      ([enterKonamiCode, noPassword, isSuperAdmin]) =>
        enterKonamiCode || noPassword || isSuperAdmin,
    ),
    shareReplay(1),
  );

  public isUnlock = toSignal(this.unlock$);

  public constructor(private readonly _tokenService: TokenService) {}
}
