@if (ready()) {
  <div
    class="border border-gray-500 border-solid rounded p-6 col-span-3"
    [formGroup]="form"
  >
    @if (index() == 0) {
      <div class="bg-gray-33 text-sm w-full p-4 rounded mb-5">
        <span i18n>
          透過設定 UTM (Urchin Tracking Module)
          參數，您可以分析流量來源，知道流量是從哪裡來的。此功能僅支援「LINE
          官方帳號」，使用時請一定要將「檢查登入狀態」打勾，詳細說明請見
        </span>
        <a
          i18n
          class="text-primary"
          target="_name"
          href="https://u.gosu.bar/utm-setup"
        >
          教學文章
        </a>
      </div>
    }

    <div class="w-full items-center grid grid-cols-3 gap-4 mt-6">
      <div class="col-span-3 flex items-center gap-1">
        <span i18n>UTM 參數設定</span>
        <mat-icon
          class="!size-4 !text-[16px] p-[1px]"
          [matTooltip]="utmTrackingTooltip()"
        >
          info
        </mat-icon>
      </div>

      @for (property of utmTrackingProperties(); track $index) {
        <mat-form-field class="col-span-1 !pb-6">
          <mat-label i18n>{{ property.name }}</mat-label>
          <input matInput [formControlName]="property.key" maxlength="100" />
          <mat-hint>
            @if (form.get(property.key)?.value?.length; as length) {
              <span i18n>字數剩餘 {{ 100 - length }} 個字</span>
            } @else {
              <span i18n>字數最多 100 個字</span>
            }
          </mat-hint>
        </mat-form-field>
      }
    </div>

    @if (customerTrackingProperties().length > 0) {
      <div class="w-full items-center grid grid-cols-3 gap-4 mt-6">
        <div class="col-span-3 flex items-center gap-1">
          <span i18n>自訂追蹤項目設定</span>
          <mat-icon
            class="!size-4 !text-[16px] p-[1px]"
            [matTooltip]="customerTrackingTooltip()"
          >
            info
          </mat-icon>
        </div>

        @for (property of customerTrackingProperties(); track $index) {
          <mat-form-field class="col-span-1 !pb-6">
            <mat-label i18n>{{ property.name }}</mat-label>
            <input matInput [formControlName]="property.key" maxlength="100" />
            <mat-hint>
              @if (form.get(property.key)?.value?.length; as length) {
                <span i18n>字數剩餘 {{ 100 - length }} 個字</span>
              } @else {
                <span i18n>字數最多 100 個字</span>
              }
            </mat-hint>
          </mat-form-field>
        }
      </div>
    }

    @if (konamiCode.unlock$ | async) {
      <div class="grid grid-cols-3 gap-4 mt-6">
        <div class="col-span-3 flex items-center gap-1">
          <span i18n>一般參數</span>
          <mat-icon
            class="!size-4 !text-[16px] p-[1px]"
            [matTooltip]="untrackParamsTooltip()"
          >
            info
          </mat-icon>
        </div>

        @for (formGroup of untrackParamsForm.controls; track $index) {
          <div [formGroup]="formGroup" class="col-span-3 flex flex-row gap-4">
            <mat-form-field class="col-span-1">
              <mat-label i18n>Key</mat-label>
              <input matInput formControlName="key" />
            </mat-form-field>

            <mat-form-field class="col-span-1">
              <mat-label i18n>Value</mat-label>
              <input matInput formControlName="value" />
            </mat-form-field>

            <div class="col-span-1 pt-2">
              <button
                mat-icon-button
                color="warn"
                (click)="untrackParamsForm.removeAt($index)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        }

        <div class="col-span-3 text-center">
          <button mat-button color="primary" (click)="addUnknownParam()">
            <mat-icon>add</mat-icon>
            <span i18n>新增一般參數</span>
          </button>
        </div>
      </div>
    }

    <div class="grid grid-cols-3 gap-4 mt-6">
      <div class="col-span-3 flex items-center gap-1">
        <span i18n>UTM 追蹤連結</span>
      </div>

      @for (bot of bots; track $index) {
        <flow-bot-url [name]="bot.name" [botId]="bot.id" [urlPath]="urlPath()">
        </flow-bot-url>
      }
    </div>

    <div class="col-span-3 text-gray-500 text-sm pt-4 text-center">
      <button
        mat-button
        color="warn"
        (click)="removeUtmTrackingCodeForm.emit(index())"
      >
        <mat-icon>delete</mat-icon>
        <span i18n>刪除</span>
      </button>
    </div>
  </div>
}
